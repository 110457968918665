var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { isNumber } from '@antv/util';
var DEFAULT_STYLE = {
  fill: '#1890ff',
  stroke: '#1890ff',
  type: 'trend',
  radius: 2,
  opacity: 1,
  cursor: 'ew-resize',
  // 高亮的颜色
  highLightFill: '#0050b3'
};
var SIMPLE_DEFAULT_STYLE = {
  fill: '#fff',
  stroke: '#1890ff',
  radius: 2,
  opacity: 1,
  cursor: 'ew-resize'
};

var Handler =
/** @class */
function () {
  function Handler(cfg) {
    var group = cfg.group,
        name = cfg.name,
        type = cfg.type,
        _a = cfg.x,
        x = _a === void 0 ? 0 : _a,
        _b = cfg.y,
        y = _b === void 0 ? 0 : _b,
        _c = cfg.width,
        width = _c === void 0 ? 2 : _c,
        _d = cfg.height,
        height = _d === void 0 ? 24 : _d,
        _e = cfg.style,
        style = _e === void 0 ? {} : _e;
    this.group = group;
    this.name = name;
    this.handleType = type;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;

    if (type === 'trend') {
      this.style = __assign(__assign({}, DEFAULT_STYLE), style);
    } else if (type === 'simple') {
      this.style = __assign(__assign({}, SIMPLE_DEFAULT_STYLE), style);
    }

    this.renderHandle();
  }
  /**
   * 设置位置 x
   * @param x
   */


  Handler.prototype.setX = function (x) {
    this.setXY(x, undefined);
  };
  /**
   * 设置位置 y
   * @param y
   */


  Handler.prototype.setY = function (y) {
    this.setXY(undefined, y);
  };

  Handler.prototype.setXY = function (x, y) {
    if (isNumber(x)) {
      this.x = x;
    }

    if (isNumber(y)) {
      this.y = y;
    }

    this.updateXY();
  };
  /**
   * 初始化组件
   * @private
   */


  Handler.prototype.renderHandle = function () {
    var _a = this,
        width = _a.width,
        height = _a.height,
        style = _a.style,
        name = _a.name;

    var fill = style.fill,
        stroke = style.stroke,
        radius = style.radius,
        opacity = style.opacity,
        cursor = style.cursor;
    this.handleGroup = this.group.addGroup(); // 趋势图时的 handle

    if (this.handleType === 'trend') {
      // 垂直线
      this.verticalLine = this.handleGroup.addShape('rect', {
        attrs: {
          x: 0,
          y: 0,
          width: width,
          height: height,
          fill: fill,
          stroke: stroke,
          radius: radius,
          opacity: opacity,
          cursor: cursor
        },
        name: name + "-handler"
      });
      this.topCircle = this.handleGroup.addShape('circle', {
        attrs: {
          x: width / 2,
          y: 0,
          r: 2 * width,
          fill: fill,
          stroke: stroke,
          radius: radius,
          opacity: opacity,
          cursor: cursor
        },
        name: name + "-handler"
      });
      this.bottomCircle = this.handleGroup.addShape('circle', {
        attrs: {
          x: width / 2,
          y: height,
          r: 2 * width,
          fill: fill,
          stroke: stroke,
          radius: radius,
          opacity: opacity,
          cursor: cursor
        },
        name: name + "-handler"
      });
    } else if (this.handleType === 'simple') {
      this.topCircle = this.handleGroup.addShape('circle', {
        attrs: {
          x: width / 2,
          y: height / 2,
          r: 2 * width,
          fill: fill,
          stroke: stroke,
          radius: radius,
          opacity: opacity,
          cursor: cursor,
          lineWidth: 2
        },
        name: name + "-handler"
      });
    } // 移动到对应的位置


    this.updateXY();

    if (this.handleType === 'trend') {
      this.bindTrendEvents();
    } else if (this.handleType === 'simple') {
      this.bindSimpleEvents();
    }
  };

  Handler.prototype.bindSimpleEvents = function () {
    var _this = this;

    var name = this.name;
    this.handleGroup.on(name + "-handler:mouseenter", function () {
      var highLightFill = _this.style.highLightFill;

      _this.topCircle.attr('fill', highLightFill);
    });
    this.handleGroup.on(name + "-handler:mouseleave", function () {
      var fill = _this.style.fill;

      _this.topCircle.attr('fill', fill);
    });
  };

  Handler.prototype.bindTrendEvents = function () {
    var _this = this;

    var name = this.name;
    this.handleGroup.on(name + "-handler:mouseenter", function () {
      var highLightFill = _this.style.highLightFill;

      _this.verticalLine.attr('fill', highLightFill);

      _this.topCircle.attr('fill', highLightFill);

      _this.bottomCircle.attr('fill', highLightFill);
    });
    this.handleGroup.on(name + "-handler:mouseleave", function () {
      var fill = _this.style.fill;

      _this.verticalLine.attr('fill', fill);

      _this.topCircle.attr('fill', fill);

      _this.bottomCircle.attr('fill', fill);
    });
  };

  Handler.prototype.show = function () {
    this.handleGroup.show();
  };

  Handler.prototype.hide = function () {
    this.handleGroup.hide();
  };

  Handler.prototype.updateXY = function () {
    this.handleGroup.setMatrix([1, 0, 0, 0, 1, 0, this.x, this.y, 1]);
  };

  return Handler;
}();

export default Handler;