import { registerBehavior, registerCombo, registerEdge, registerNode, Arrow, Marker, Shape } from '@antv/g6-core';
import * as Algorithm from '@antv/algorithm';
import Graph from './graph/graph';
import TreeGraph from './graph/tree-graph';
import { Layout, registerLayout } from './layout';
import Global from './global';
import Util from './util';
import Plugin from './plugin';
import './element';
import './behavior';
var Minimap = Plugin.Minimap;
var Grid = Plugin.Grid;
var Bundling = Plugin.Bundling;
var Menu = Plugin.Menu;
var Fisheye = Plugin.Fisheye;
var ToolBar = Plugin.ToolBar;
var Tooltip = Plugin.Tooltip;
var TimeBar = Plugin.TimeBar;
var ImageMinimap = Plugin.ImageMinimap;
var EdgeFilterLens = Plugin.EdgeFilterLens;
export * from '@antv/g6-core';
export * from './types';
export * from './interface/graph';
export { Graph, TreeGraph, Util, Layout, registerLayout, Global, Minimap, Grid, Bundling, Menu, Fisheye, Algorithm, ToolBar, Tooltip, TimeBar, ImageMinimap, EdgeFilterLens, Arrow, Marker, Shape };
export default {
  version: Global.version,
  Graph: Graph,
  TreeGraph: TreeGraph,
  Util: Util,
  Layout: Layout,
  registerLayout: registerLayout,
  Global: Global,
  registerBehavior: registerBehavior,
  registerCombo: registerCombo,
  registerEdge: registerEdge,
  registerNode: registerNode,
  Minimap: Plugin.Minimap,
  Grid: Plugin.Grid,
  Bundling: Plugin.Bundling,
  Menu: Plugin.Menu,
  ToolBar: Plugin.ToolBar,
  Tooltip: Plugin.Tooltip,
  TimeBar: TimeBar,
  Fisheye: Fisheye,
  ImageMinimap: ImageMinimap,
  EdgeFilterLens: EdgeFilterLens,
  Algorithm: Algorithm,
  Arrow: Arrow,
  Marker: Marker,
  Shape: Shape
};