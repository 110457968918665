import { isNaN } from '../../util/base';

var LayoutController =
/** @class */
function () {
  function LayoutController(graph) {
    this.graph = graph;
    this.layoutCfg = graph.get('layout') || {};
    this.layoutType = this.layoutCfg.type;
    this.initLayout();
  } // eslint-disable-next-line class-methods-use-this


  LayoutController.prototype.initLayout = function () {// no data before rendering
  };

  LayoutController.prototype.getLayoutType = function () {
    return this.layoutCfg.type;
  }; // 绘制


  LayoutController.prototype.refreshLayout = function () {
    var graph = this.graph;
    if (!graph) return;

    if (graph.get('animate')) {
      graph.positionsAnimate();
    } else {
      graph.refreshPositions();
    }
  }; // 更换布局


  LayoutController.prototype.changeLayout = function (layoutType) {
    var _a = this,
        graph = _a.graph,
        layoutMethod = _a.layoutMethod;

    this.layoutType = layoutType;
    this.layoutCfg = graph.get('layout') || {};
    this.layoutCfg.type = layoutType;

    if (layoutMethod) {
      layoutMethod.destroy();
    }

    this.layout();
  }; // 更换数据


  LayoutController.prototype.changeData = function () {
    var layoutMethod = this.layoutMethod;

    if (layoutMethod) {
      layoutMethod.destroy();
    }

    this.layout();
  }; // 销毁布局，不能使用 this.destroy，因为 controller 还需要被使用，只是把布局算法销毁


  LayoutController.prototype.destroyLayout = function () {
    var _a = this,
        layoutMethod = _a.layoutMethod,
        graph = _a.graph;

    if (layoutMethod) {
      layoutMethod.destroy();
    }

    graph.set('layout', undefined);
    this.layoutCfg = undefined;
    this.layoutType = undefined;
    this.layoutMethod = undefined;
  }; // 从 this.graph 获取数据


  LayoutController.prototype.setDataFromGraph = function () {
    var nodes = [];
    var edges = [];
    var combos = [];
    var nodeItems = this.graph.getNodes();
    var edgeItems = this.graph.getEdges();
    var comboItems = this.graph.getCombos();
    var nodeLength = nodeItems.length;

    for (var i = 0; i < nodeLength; i++) {
      var nodeItem = nodeItems[i];
      if (!nodeItem.isVisible()) continue;
      var model = nodeItem.getModel();
      nodes.push(model);
    }

    var edgeLength = edgeItems.length;

    for (var i = 0; i < edgeLength; i++) {
      var edgeItem = edgeItems[i];
      if (!edgeItem || edgeItem.destroyed || !edgeItem.isVisible()) continue;
      var model = edgeItem.getModel();
      if (!model.isComboEdge) edges.push(model);
    }

    var comboLength = comboItems.length;

    for (var i = 0; i < comboLength; i++) {
      var comboItem = comboItems[i];
      if (comboItem.destroyed || !comboItem.isVisible()) continue;
      var model = comboItem.getModel();
      combos.push(model);
    }

    return {
      nodes: nodes,
      edges: edges,
      combos: combos
    };
  }; // 重新布局


  LayoutController.prototype.relayout = function (reloadData) {
    var _a = this,
        graph = _a.graph,
        layoutMethod = _a.layoutMethod,
        layoutCfg = _a.layoutCfg;

    if (reloadData) {
      this.data = this.setDataFromGraph();
      var nodes = this.data.nodes;

      if (!nodes) {
        return false;
      }

      this.initPositions(layoutCfg.center, nodes);
      layoutMethod.init(this.data);
    }

    if (this.layoutType === 'force') {
      layoutMethod.ticking = false;
      layoutMethod.forceSimulation.stop();
    }

    graph.emit('beforelayout');
    layoutMethod.execute(reloadData);
    if (layoutMethod.isCustomLayout && layoutCfg.onLayoutEnd) layoutCfg.onLayoutEnd();
  }; // 控制布局动画
  // eslint-disable-next-line class-methods-use-this


  LayoutController.prototype.layoutAnimate = function () {}; // 将当前节点的平均中心移动到原点


  LayoutController.prototype.moveToZero = function () {
    var graph = this.graph;
    var data = graph.get('data');
    var nodes = data.nodes;

    if (nodes[0].x === undefined || nodes[0].x === null || isNaN(nodes[0].x)) {
      return;
    }

    var meanCenter = [0, 0];
    var nodeLength = nodes.length;

    for (var i = 0; i < nodeLength; i++) {
      var node = nodes[i];
      meanCenter[0] += node.x;
      meanCenter[1] += node.y;
    }

    meanCenter[0] /= nodes.length;
    meanCenter[1] /= nodes.length;

    for (var i = 0; i < nodeLength; i++) {
      var node = nodes[i];
      node.x -= meanCenter[0];
      node.y -= meanCenter[1];
    }
  }; // 初始化节点到 center 附近


  LayoutController.prototype.initPositions = function (center, nodes) {
    var graph = this.graph;

    if (!nodes) {
      return false;
    }

    var allHavePos = true;
    var width = graph.get('width') * 0.85;
    var height = graph.get('height') * 0.85;
    var nodeNum = nodes.length;
    var horiNum = Math.ceil(Math.sqrt(nodeNum) * (width / height));
    var vertiNum = Math.ceil(nodeNum / horiNum);
    var horiGap = width / (horiNum - 1);
    var vertiGap = height / (vertiNum - 1);
    if (!isFinite(horiGap) || !horiGap) horiGap = 0;
    if (!isFinite(vertiGap) || !horiGap) vertiGap = 0;
    var beginX = center[0] - width / 2;
    var beginY = center[1] - height / 2;
    var nodeLength = nodes.length;

    for (var i = 0; i < nodeLength; i++) {
      var node = nodes[i];

      if (isNaN(node.x)) {
        allHavePos = false;
        node.x = i % horiNum * horiGap + beginX;
      }

      if (isNaN(node.y)) {
        allHavePos = false;
        node.y = Math.floor(i / horiNum) * vertiGap + beginY;
      }
    }

    return allHavePos;
  };

  LayoutController.prototype.destroy = function () {
    var layoutMethod = this.layoutMethod;
    this.graph = null;

    if (layoutMethod) {
      layoutMethod.destroy();
      layoutMethod.destroyed = true;
    }

    this.destroyed = true;
  };

  return LayoutController;
}();

export default LayoutController;