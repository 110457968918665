var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { dataToPath, linePathToAreaPath, dataToRectPath } from './path';
export var LINE_STYLE = {
  stroke: '#C5C5C5',
  strokeOpacity: 0.85
};
export var AREA_STYLE = {
  fill: '#CACED4',
  opacity: 0.85
};
/**
 * 缩略趋势图
 */

var Trend =
/** @class */
function () {
  function Trend(cfg) {
    var _a = cfg.x,
        x = _a === void 0 ? 0 : _a,
        _b = cfg.y,
        y = _b === void 0 ? 0 : _b,
        _c = cfg.width,
        width = _c === void 0 ? 200 : _c,
        _d = cfg.height,
        height = _d === void 0 ? 26 : _d,
        _e = cfg.smooth,
        smooth = _e === void 0 ? true : _e,
        _f = cfg.isArea,
        isArea = _f === void 0 ? false : _f,
        _g = cfg.data,
        data = _g === void 0 ? [] : _g,
        lineStyle = cfg.lineStyle,
        areaStyle = cfg.areaStyle,
        group = cfg.group,
        _h = cfg.interval,
        interval = _h === void 0 ? null : _h;
    this.group = group;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.data = data;
    this.smooth = smooth;
    this.isArea = isArea;
    this.lineStyle = Object.assign({}, LINE_STYLE, lineStyle);
    this.areaStyle = Object.assign({}, AREA_STYLE, areaStyle);
    this.intervalConfig = interval;
    this.renderLine();
  }
  /**
   * 构造
   * @private
   */


  Trend.prototype.renderLine = function () {
    var _a = this,
        x = _a.x,
        y = _a.y,
        width = _a.width,
        height = _a.height,
        data = _a.data,
        smooth = _a.smooth,
        isArea = _a.isArea,
        lineStyle = _a.lineStyle,
        areaStyle = _a.areaStyle;

    var trendGroup = this.group.addGroup({
      name: 'trend-group'
    });

    if (data) {
      var path = dataToPath(data, width, height, smooth); // 线

      trendGroup.addShape('path', {
        attrs: __assign({
          path: path
        }, lineStyle)
      }); // 在 line 的基础上，绘制面积图

      if (isArea) {
        var areaPath = linePathToAreaPath(path, width, height, data);
        trendGroup.addShape('path', {
          attrs: __assign({
            path: areaPath
          }, areaStyle)
        });
      }
    } // 绘制柱状图📊


    if (this.intervalConfig) {
      trendGroup.addShape('path', {
        attrs: __assign({
          path: dataToRectPath(this.intervalConfig.data, width, height)
        }, this.intervalConfig.style)
      });
    } // 统一移动到对应的位置


    trendGroup.move(x, y);
  };

  Trend.prototype.destory = function () {
    this.group.destroy();
  };

  return Trend;
}();

export default Trend;