import { G6Event } from '@antv/g6-core';
import Util from '../util';
var cloneEvent = Util.cloneEvent,
    isNaN = Util.isNaN;
var abs = Math.abs;
var DRAG_OFFSET = 10;
var ALLOW_EVENTS = ['shift', 'ctrl', 'alt', 'control'];
export default {
  getDefaultCfg: function getDefaultCfg() {
    return {
      direction: 'both',
      enableOptimize: false,
      // drag-canvas 可拖动的扩展范围，默认为 0，即最多可以拖动一屏的位置
      // 当设置的值大于 0 时，即拖动可以超过一屏
      // 当设置的值小于 0 时，相当于缩小了可拖动范围
      // 具体实例可参考：https://gw.alipayobjects.com/mdn/rms_f8c6a0/afts/img/A*IFfoS67_HssAAAAAAAAAAAAAARQnAQ
      scalableRange: 0,
      allowDragOnItem: false
    };
  },
  getEvents: function getEvents() {
    return {
      dragstart: 'onMouseDown',
      drag: 'onMouseMove',
      dragend: 'onMouseUp',
      'canvas:click': 'onMouseUp',
      keyup: 'onKeyUp',
      focus: 'onKeyUp',
      keydown: 'onKeyDown',
      touchstart: 'onTouchStart',
      touchmove: 'onTouchMove',
      touchend: 'onMouseUp'
    };
  },
  updateViewport: function updateViewport(e) {
    var origin = this.origin;
    var clientX = +e.clientX;
    var clientY = +e.clientY;

    if (isNaN(clientX) || isNaN(clientY)) {
      return;
    }

    var dx = clientX - origin.x;
    var dy = clientY - origin.y;

    if (this.get('direction') === 'x') {
      dy = 0;
    } else if (this.get('direction') === 'y') {
      dx = 0;
    }

    this.origin = {
      x: clientX,
      y: clientY
    };
    var width = this.graph.get('width');
    var height = this.graph.get('height');
    var graphCanvasBBox = this.graph.get('canvas').getCanvasBBox();

    if (graphCanvasBBox.minX <= width + this.scalableRange && graphCanvasBBox.minX + dx > width + this.scalableRange || graphCanvasBBox.maxX + this.scalableRange >= 0 && graphCanvasBBox.maxX + this.scalableRange + dx < 0) {
      dx = 0;
    }

    if (graphCanvasBBox.minY <= height + this.scalableRange && graphCanvasBBox.minY + dy > height + this.scalableRange || graphCanvasBBox.maxY + this.scalableRange >= 0 && graphCanvasBBox.maxY + this.scalableRange + dy < 0) {
      dy = 0;
    }

    this.graph.translate(dx, dy);
  },
  onTouchStart: function onTouchStart(e) {
    var self = this;
    var touches = e.originalEvent.touches;
    var event1 = touches[0];
    var event2 = touches[1]; // 如果是双指操作，不允许拖拽画布

    if (event1 && event2) {
      return;
    }

    e.preventDefault();
    self.onMouseDown(e);
  },
  onMouseDown: function onMouseDown(e) {
    var self = this;
    var event = e.originalEvent;

    if (event && e.name !== G6Event.TOUCHSTART && event.button !== 0) {
      return;
    }

    if (e.name !== G6Event.TOUCHSTART && typeof window !== 'undefined' && window.event && !window.event.buttons && !window.event.button) {
      return;
    }

    if (!this.shouldBegin.call(this, e)) {
      return;
    }

    if (self.keydown) return;
    var target = e.target;
    var targetIsCanvas = target && target.isCanvas && target.isCanvas();
    if (!this.allowDragOnItem && !targetIsCanvas) return;
    self.origin = {
      x: e.clientX,
      y: e.clientY
    };
    self.dragging = false;

    if (this.enableOptimize) {
      // 拖动 canvas 过程中隐藏所有的边及label
      var graph = this.graph;
      var edges = graph.getEdges();

      for (var i = 0, len = edges.length; i < len; i++) {
        var shapes = edges[i].get('group').get('children');
        if (!shapes) continue;
        shapes.forEach(function (shape) {
          shape.set('ori-visibility', shape.get('ori-visibility') || shape.get('visible'));
          shape.hide();
        });
      }

      var nodes = graph.getNodes();

      for (var j = 0, nodeLen = nodes.length; j < nodeLen; j++) {
        var container = nodes[j].getContainer();
        var children = container.get('children');

        for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
          var child = children_1[_i];
          var isKeyShape = child.get('isKeyShape');

          if (!isKeyShape) {
            child.set('ori-visibility', child.get('ori-visibility') || child.get('visible'));
            child.hide();
          }
        }
      }
    }
  },
  onTouchMove: function onTouchMove(e) {
    var self = this;
    var touches = e.originalEvent.touches;
    var event1 = touches[0];
    var event2 = touches[1]; // 如果是双指操作，不允许拖拽画布，结束拖拽

    if (event1 && event2) {
      this.onMouseUp(e);
      return;
    }

    e.preventDefault();
    self.onMouseMove(e);
  },
  onMouseMove: function onMouseMove(e) {
    var graph = this.graph;
    if (this.keydown) return;
    var target = e.target;
    var targetIsCanvas = target && target.isCanvas && target.isCanvas();
    if (!this.allowDragOnItem && !targetIsCanvas) return;
    e = cloneEvent(e);

    if (!this.origin) {
      return;
    }

    if (!this.dragging) {
      if (abs(this.origin.x - e.clientX) + abs(this.origin.y - e.clientY) < DRAG_OFFSET) {
        return;
      }

      if (this.shouldBegin.call(this, e)) {
        e.type = 'dragstart';
        graph.emit('canvas:dragstart', e);
        this.dragging = true;
      }
    } else {
      e.type = 'drag';
      graph.emit('canvas:drag', e);
    }

    if (this.shouldUpdate.call(this, e)) {
      this.updateViewport(e);
    }
  },
  onMouseUp: function onMouseUp(e) {
    var graph = this.graph;
    if (this.keydown) return;

    if (this.enableOptimize) {
      // 拖动结束后显示所有的边
      var edges = graph.getEdges();

      for (var i = 0, len = edges.length; i < len; i++) {
        var shapes = edges[i].get('group').get('children');
        if (!shapes) continue;
        shapes.forEach(function (shape) {
          var oriVis = shape.get('ori-visibility');
          if (oriVis) shape.show();
        });
      }

      var nodes = graph.getNodes();

      for (var j = 0, nodeLen = nodes.length; j < nodeLen; j++) {
        var container = nodes[j].getContainer();
        var children = container.get('children');

        for (var _i = 0, children_2 = children; _i < children_2.length; _i++) {
          var child = children_2[_i];
          var isKeyShape = child.get('isKeyShape');

          if (!isKeyShape) {
            var oriVis = child.get('ori-visibility');
            if (oriVis) child.show();
          }
        }
      }
    }

    if (!this.dragging) {
      this.origin = null;
      return;
    }

    e = cloneEvent(e);

    if (this.shouldEnd.call(this, e)) {
      this.updateViewport(e);
    }

    e.type = 'dragend';
    graph.emit('canvas:dragend', e);
    this.endDrag();
  },
  endDrag: function endDrag() {
    this.origin = null;
    this.dragging = false;
    this.dragbegin = false;
  },
  onKeyDown: function onKeyDown(e) {
    var self = this;
    var code = e.key;

    if (!code) {
      return;
    }

    if (ALLOW_EVENTS.indexOf(code.toLowerCase()) > -1) {
      self.keydown = true;
    } else {
      self.keydown = false;
    }
  },
  onKeyUp: function onKeyUp() {
    this.keydown = false;
    this.origin = null;
    this.dragging = false;
    this.dragbegin = false;
  }
};