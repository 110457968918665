var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");

    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { clone } from '@antv/util';
import { Util } from '@antv/g6-core';
import Base from '../base';
var distance = Util.distance;
var DELTA = 0.05;
var lensDelegateStyle = {
  stroke: '#000',
  strokeOpacity: 0.8,
  lineWidth: 2,
  fillOpacity: 1,
  fill: '#fff'
};

var EdgeFilterLens =
/** @class */
function (_super) {
  __extends(EdgeFilterLens, _super);

  function EdgeFilterLens() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  EdgeFilterLens.prototype.getDefaultCfgs = function () {
    return {
      type: 'both',
      trigger: 'mousemove',
      r: 60,
      delegateStyle: clone(lensDelegateStyle),
      showLabel: 'edge',
      scaleRBy: 'wheel'
    };
  }; // class-methods-use-this


  EdgeFilterLens.prototype.getEvents = function () {
    var events;

    switch (this.get('trigger')) {
      case 'click':
        events = {
          click: 'filter'
        };
        break;

      case 'drag':
        events = {
          click: 'createDelegate'
        };
        break;

      default:
        events = {
          mousemove: 'filter'
        };
        break;
    }

    return events;
  };

  EdgeFilterLens.prototype.init = function () {
    var self = this;
    var showLabel = self.get('showLabel');
    var showNodeLabel = showLabel === 'node' || showLabel === 'both';
    var showEdgeLabel = showLabel === 'edge' || showLabel === 'both';
    self.set('showNodeLabel', showNodeLabel);
    self.set('showEdgeLabel', showEdgeLabel);
    var shouldShow = self.get('shouldShow');
    if (!shouldShow) self.set('shouldShow', function () {
      return true;
    });
  }; // Create the delegate when the trigger is drag


  EdgeFilterLens.prototype.createDelegate = function (e) {
    var self = this;
    var lensDelegate = self.get('delegate');

    if (!lensDelegate || lensDelegate.destroyed) {
      self.filter(e);
      lensDelegate = self.get('delegate'); // drag to move the lens

      lensDelegate.on('dragstart', function (evt) {});
      lensDelegate.on('drag', function (evt) {
        self.filter(evt);
      }); // 绑定调整范围（r）
      // 由于 drag 用于改变 lens 位置，因此在此模式下，drag 不能用于调整 r
      // scaling r

      if (this.get('scaleRBy') === 'wheel') {
        lensDelegate.on('mousewheel', function (evt) {
          self.scaleRByWheel(evt);
        });
      }
    }
  };
  /**
   * Scale the range by wheel
   * @param e mouse wheel event
   */


  EdgeFilterLens.prototype.scaleRByWheel = function (e) {
    var self = this;
    if (!e || !e.originalEvent) return;
    if (e.preventDefault) e.preventDefault();
    var graph = self.get('graph');
    var ratio;
    var lensDelegate = self.get('delegate');
    var lensCenter = lensDelegate ? {
      x: lensDelegate.attr('x'),
      y: lensDelegate.attr('y')
    } : undefined;
    var mousePos = lensCenter || graph.getPointByClient(e.clientX, e.clientY);

    if (e.originalEvent.wheelDelta < 0) {
      ratio = 1 - DELTA;
    } else {
      ratio = 1 / (1 - DELTA);
    }

    var maxR = self.get('maxR');
    var minR = self.get('minR');
    var r = self.get('r');

    if (r > (maxR || graph.get('height')) && ratio > 1 || r < (minR || graph.get('height') * 0.05) && ratio < 1) {
      ratio = 1;
    }

    r *= ratio;
    self.set('r', r);
    self.filter(e);
  };
  /**
   * Response function for mousemove, click, or drag to filter out the edges
   * @param e mouse event
   */


  EdgeFilterLens.prototype.filter = function (e) {
    var self = this;
    var graph = self.get('graph');
    var nodes = graph.getNodes();
    var hitNodesMap = {};
    var r = self.get('r');
    var type = self.get('type');
    var fCenter = {
      x: e.x,
      y: e.y
    };
    self.updateDelegate(fCenter, r);
    var shouldShow = self.get('shouldShow');
    var vShapes = self.get('vShapes');

    if (vShapes) {
      vShapes.forEach(function (shape) {
        shape.remove();
        shape.destroy();
      });
    }

    vShapes = [];
    nodes.forEach(function (node) {
      var model = node.getModel();
      var x = model.x,
          y = model.y;

      if (distance({
        x: x,
        y: y
      }, fCenter) < r) {
        hitNodesMap[model.id] = node;
      }
    });
    var edges = graph.getEdges();
    var hitEdges = [];
    edges.forEach(function (edge) {
      var model = edge.getModel();
      var sourceId = model.source;
      var targetId = model.target;

      if (shouldShow(model)) {
        if (type === 'only-source' || type === 'one') {
          if (hitNodesMap[sourceId] && !hitNodesMap[targetId]) hitEdges.push(edge);
        } else if (type === 'only-target' || type === 'one') {
          if (hitNodesMap[targetId] && !hitNodesMap[sourceId]) hitEdges.push(edge);
        } else if (type === 'both' && hitNodesMap[sourceId] && hitNodesMap[targetId]) {
          hitEdges.push(edge);
        }
      }
    });
    var showNodeLabel = self.get('showNodeLabel');
    var showEdgeLabel = self.get('showEdgelabel'); // copy the shapes in hitEdges

    var group = graph.get('group');
    hitEdges.forEach(function (edge) {
      var shapes = edge.get('group').get('children');
      shapes.forEach(function (shape) {
        var shapeType = shape.get('type');
        var vShape = group.addShape(shapeType, {
          attrs: shape.attr()
        });
        vShapes.push(vShape);

        if (showNodeLabel && shapeType === 'text') {
          vShape.set('visible', true);
        }
      });
    }); // copy the shape sof hitNodes

    Object.keys(hitNodesMap).forEach(function (key) {
      var node = hitNodesMap[key];
      var clonedGroup = node.get('group').clone();
      group.add(clonedGroup);
      vShapes.push(clonedGroup);

      if (showEdgeLabel) {
        var shapes = clonedGroup.get('children');

        for (var j = 0; j < shapes.length; j++) {
          var shape = shapes[j];

          if (shape.get('type') === 'text') {
            shape.set('visible', true);
          }
        }
      }
    });
    self.set('vShapes', vShapes);
  };
  /**
   * Adjust part of the parameters, including trigger, type, r, maxR, minR, shouldShow, showLabel, and scaleRBy
   * @param {EdgeFilterLensConfig} cfg
   */


  EdgeFilterLens.prototype.updateParams = function (cfg) {
    var self = this;
    var r = cfg.r,
        trigger = cfg.trigger,
        minR = cfg.minR,
        maxR = cfg.maxR,
        scaleRBy = cfg.scaleRBy,
        showLabel = cfg.showLabel,
        shouldShow = cfg.shouldShow;

    if (!isNaN(cfg.r)) {
      self.set('r', r);
    }

    if (!isNaN(maxR)) {
      self.set('maxR', maxR);
    }

    if (!isNaN(minR)) {
      self.set('minR', minR);
    }

    if (trigger === 'mousemove' || trigger === 'click') {
      self.set('trigger', trigger);
    }

    if (scaleRBy === 'wheel' || scaleRBy === 'unset') {
      self.set('scaleRBy', scaleRBy);
      self.get('delegate').remove();
      self.get('delegate').destroy();
      var dPercentText = self.get('dPercentText');

      if (dPercentText) {
        dPercentText.remove();
        dPercentText.destroy();
      }
    }

    if (showLabel === 'node' || showLabel === 'both') {
      self.set('showNodeLabel', true);
    }

    if (showLabel === 'edge' || showLabel === 'both') {
      self.set('showEdgeLabel', true);
    }

    if (shouldShow) {
      self.set('shouldShow', shouldShow);
    }
  };
  /**
   * Update the delegate shape of the lens
   * @param {Point} mCenter the center of the shape
   * @param {number} r the radius of the shape
   */


  EdgeFilterLens.prototype.updateDelegate = function (mCenter, r) {
    var self = this;
    var graph = self.get('graph');
    var lensDelegate = self.get('delegate');

    if (!lensDelegate || lensDelegate.destroyed) {
      // 拖动多个
      var parent_1 = graph.get('group');
      var attrs = self.get('delegateStyle') || lensDelegateStyle; // model上的x, y是相对于图形中心的，delegateShape是g实例，x,y是绝对坐标

      lensDelegate = parent_1.addShape('circle', {
        attrs: __assign({
          r: r,
          x: mCenter.x,
          y: mCenter.y
        }, attrs),
        name: 'lens-shape',
        draggable: true
      });

      if (this.get('trigger') !== 'drag') {
        // 调整范围 r 的监听
        if (this.get('scaleRBy') === 'wheel') {
          // 使用滚轮调整 r
          lensDelegate.on('mousewheel', function (evt) {
            self.scaleRByWheel(evt);
          });
        }
      }
    } else {
      lensDelegate.attr({
        x: mCenter.x,
        y: mCenter.y,
        r: r
      });
    }

    self.set('delegate', lensDelegate);
  };
  /**
   * Clear the filtering
   */


  EdgeFilterLens.prototype.clear = function () {
    var self = this;
    var vShapes = self.get('vShapes');

    if (vShapes) {
      vShapes.forEach(function (shape) {
        shape.remove();
        shape.destroy();
      });
    }

    vShapes = [];
    self.set('vShapes', vShapes);
    var lensDelegate = self.get('delegate');

    if (lensDelegate && !lensDelegate.destroyed) {
      lensDelegate.remove();
      lensDelegate.destroy();
    }
  };
  /**
   * Destroy the component
   */


  EdgeFilterLens.prototype.destroy = function () {
    this.clear();
  };

  return EdgeFilterLens;
}(Base);

export default EdgeFilterLens;