export var getBBoxFromPoint = function getBBoxFromPoint(point) {
  var x = point.x,
      y = point.y;
  return {
    x: x,
    y: y,
    centerX: x,
    centerY: y,
    minX: x,
    minY: y,
    maxX: x,
    maxY: y,
    height: 0,
    width: 0
  };
};
export var getBBoxFromPoints = function getBBoxFromPoints(points) {
  if (points === void 0) {
    points = [];
  }

  var xs = [];
  var ys = [];
  points.forEach(function (p) {
    xs.push(p.x);
    ys.push(p.y);
  });
  var minX = Math.min.apply(Math, xs);
  var maxX = Math.max.apply(Math, xs);
  var minY = Math.min.apply(Math, ys);
  var maxY = Math.max.apply(Math, ys);
  return {
    centerX: (minX + maxX) / 2,
    centerY: (minY + maxY) / 2,
    maxX: maxX,
    maxY: maxY,
    minX: minX,
    minY: minY,
    height: maxY - minY,
    width: maxX - minX
  };
};
export var isBBoxesOverlapping = function isBBoxesOverlapping(b1, b2) {
  return Math.abs(b1.centerX - b2.centerX) * 2 < b1.width + b2.width && Math.abs(b1.centerY - b2.centerY) * 2 < b1.height + b2.height;
};
export var filterConnectPoints = function filterConnectPoints(points) {
  // pre-process: remove duplicated points
  var result = [];
  var pointsMap = {};
  var pointsLength = points.length;

  for (var i = pointsLength - 1; i >= 0; i--) {
    var p = points[i];
    p.id = p.x + "|||" + p.y;
    pointsMap[p.id] = p;
    result.push(p);
  }

  return result;
};
export var simplifyPolyline = function simplifyPolyline(points) {
  return filterConnectPoints(points);
};
export var getSimplePolyline = function getSimplePolyline(sPoint, tPoint) {
  return [sPoint, {
    x: sPoint.x,
    y: tPoint.y
  }, tPoint];
};
export var getExpandedBBox = function getExpandedBBox(bbox, offset) {
  if (bbox.width || bbox.height) {
    return {
      centerX: bbox.centerX,
      centerY: bbox.centerY,
      minX: bbox.minX - offset,
      minY: bbox.minY - offset,
      maxX: bbox.maxX + offset,
      maxY: bbox.maxY + offset,
      height: bbox.height + 2 * offset,
      width: bbox.width + 2 * offset
    };
  } // when it is a point


  return bbox;
};
export var isHorizontalPort = function isHorizontalPort(port, bbox) {
  var dx = Math.abs(port.x - bbox.centerX);
  var dy = Math.abs(port.y - bbox.centerY);
  if (dx === 0 && dy === 0) return 0;
  return dx / bbox.width > dy / bbox.height;
};
export var getExpandedBBoxPoint = function getExpandedBBoxPoint(bbox, // 将原来节点 bbox 扩展了 offset 后的 bbox，且被 gridSize 格式化
point, // 被 gridSize 格式化后的位置（anchorPoint）
anotherPoint) {
  var isHorizontal = isHorizontalPort(point, bbox);

  if (isHorizontal === 0) {
    // 说明锚点是节点中心，linkCenter: true。需要根据两个节点的相对关系决定方向
    var x = bbox.centerX;
    var y = bbox.centerY;

    if (anotherPoint.y < point.y) {
      // 另一端在左上/右上方时，总是从上方走
      y = bbox.minY;
    } else if (anotherPoint.x > point.x) {
      // 另一端在右下方，往右边走
      x = bbox.maxX;
    } else if (anotherPoint.x < point.x) {
      // 另一端在左下方，往左边走
      x = bbox.minX;
    } else if (anotherPoint.x === point.x) {
      // 另一段在正下方，往下走
      y = bbox.maxY;
    }

    return {
      x: x,
      y: y
    };
  }

  if (isHorizontal) {
    return {
      x: point.x > bbox.centerX ? bbox.maxX : bbox.minX,
      y: point.y
    };
  }

  return {
    x: point.x,
    y: point.y > bbox.centerY ? bbox.maxY : bbox.minY
  };
};
/**
 *
 * @param b1
 * @param b2
 */

export var mergeBBox = function mergeBBox(b1, b2) {
  var minX = Math.min(b1.minX, b2.minX);
  var minY = Math.min(b1.minY, b2.minY);
  var maxX = Math.max(b1.maxX, b2.maxX);
  var maxY = Math.max(b1.maxY, b2.maxY);
  return {
    centerX: (minX + maxX) / 2,
    centerY: (minY + maxY) / 2,
    minX: minX,
    minY: minY,
    maxX: maxX,
    maxY: maxY,
    height: maxY - minY,
    width: maxX - minX
  };
};
export var getPointsFromBBox = function getPointsFromBBox(bbox) {
  // anticlockwise
  // const { minX, minY, maxX, maxY } = bbox;
  return [{
    x: bbox.minX,
    y: bbox.minY
  }, {
    x: bbox.maxX,
    y: bbox.minY
  }, {
    x: bbox.maxX,
    y: bbox.maxY
  }, {
    x: bbox.minX,
    y: bbox.maxY
  }];
};
export var isPointOutsideBBox = function isPointOutsideBBox(point, bbox) {
  var x = point.x,
      y = point.y;
  return x < bbox.minX || x > bbox.maxX || y < bbox.minY || y > bbox.maxY;
};
export var getBBoxXCrossPoints = function getBBoxXCrossPoints(bbox, x) {
  if (x < bbox.minX || x > bbox.maxX) {
    return [];
  }

  return [{
    x: x,
    y: bbox.minY
  }, {
    x: x,
    y: bbox.maxY
  }];
};
export var getBBoxYCrossPoints = function getBBoxYCrossPoints(bbox, y) {
  if (y < bbox.minY || y > bbox.maxY) {
    return [];
  }

  return [{
    x: bbox.minX,
    y: y
  }, {
    x: bbox.maxX,
    y: y
  }];
};
export var getBBoxCrossPointsByPoint = function getBBoxCrossPointsByPoint(bbox, point) {
  return getBBoxXCrossPoints(bbox, point.x).concat(getBBoxYCrossPoints(bbox, point.y));
};
/**
 * 曼哈顿距离
 */

export var distance = function distance(p1, p2) {
  return Math.abs(p1.x - p2.x) + Math.abs(p1.y - p2.y);
};
/**
 * 如果 points 中的一个节点 x 与 p 相等，则消耗 -2。y 同
 * 即优先选择和 points 在同一水平线 / 垂直线上的点
 */
// eslint-disable-next-line @typescript-eslint/naming-convention

export var _costByPoints = function _costByPoints(p, points) {
  var offset = -2;
  var result = 0;
  points.forEach(function (point) {
    if (point) {
      if (p.x === point.x) {
        result += offset;
      }

      if (p.y === point.y) {
        result += offset;
      }
    }
  });
  return result;
};
/**
 * ps 经过 p 到 pt 的距离，减去其他路过节点造成的消耗
 */

export var heuristicCostEstimate = function heuristicCostEstimate(p, ps, pt, source, target) {
  return distance(p, ps) + distance(p, pt) + _costByPoints(p, [ps, pt, source, target]);
};
export var reconstructPath = function reconstructPath(pathPoints, pointById, cameFrom, currentId, iterator) {
  if (iterator === void 0) {
    iterator = 0;
  }

  pathPoints.unshift(pointById[currentId]);

  if (cameFrom[currentId] && cameFrom[currentId] !== currentId && iterator <= 100) {
    reconstructPath(pathPoints, pointById, cameFrom, cameFrom[currentId], iterator + 1);
  }
};
/**
 * 从 arr 中删去 item
 */

export var removeFrom = function removeFrom(arr, item) {
  var index = arr.indexOf(item);

  if (index > -1) {
    arr.splice(index, 1);
  }
};
export var isSegmentsIntersected = function isSegmentsIntersected(p0, p1, p2, p3) {
  var v1x = p2.x - p0.x;
  var v1y = p2.y - p0.y;
  var v2x = p3.x - p0.x;
  var v2y = p3.y - p0.y;
  var v3x = p2.x - p1.x;
  var v3y = p2.y - p1.y;
  var v4x = p3.x - p1.x;
  var v4y = p3.y - p1.y;
  var pd1 = v1x * v2y - v1y * v2x;
  var pd2 = v3x * v4y - v3y * v4x;
  var pd3 = v1x * v3y - v1y * v3x;
  var pd4 = v2x * v4y - v2y * v4x;
  return pd1 * pd2 <= 0 && pd3 * pd4 <= 0;
};
export var isSegmentCrossingBBox = function isSegmentCrossingBBox(p1, p2, bbox) {
  if (bbox.width || bbox.height) {
    var _a = getPointsFromBBox(bbox),
        pa = _a[0],
        pb = _a[1],
        pc = _a[2],
        pd = _a[3];

    return isSegmentsIntersected(p1, p2, pa, pb) || isSegmentsIntersected(p1, p2, pa, pd) || isSegmentsIntersected(p1, p2, pb, pc) || isSegmentsIntersected(p1, p2, pc, pd);
  }

  return false;
};
/**
 * 在 points 中找到满足 x 或 y 和 point 的 x 或 y 相等，且与 point 连线不经过 bbox1 与 bbox2 的点
 */

export var getNeighborPoints = function getNeighborPoints(points, point, bbox1, bbox2) {
  var neighbors = [];
  points.forEach(function (p) {
    if (p === point) return;

    if (p.x === point.x || p.y === point.y) {
      if (isSegmentCrossingBBox(p, point, bbox1) || isSegmentCrossingBBox(p, point, bbox2)) return;
      neighbors.push(p);
    }
  });
  return filterConnectPoints(neighbors);
};
export var pathFinder = function pathFinder(points, start, goal, sBBox, tBBox, os, ot) {
  // A-Star Algorithm
  var closedSet = [];
  var openSet = [start];
  var cameFrom = {};
  var gScore = {}; // all default values are Infinity

  var fScore = {}; // all default values are Infinity

  gScore[start.id] = 0;
  fScore[start.id] = heuristicCostEstimate(start, goal, start);
  var pointById = {};
  points.forEach(function (p) {
    pointById[p.id] = p;
  });
  var current, lowestFScore;

  while (openSet.length) {
    current = undefined;
    lowestFScore = Infinity; // 找到 openSet 中 fScore 最小的点

    openSet.forEach(function (p) {
      if (fScore[p.id] <= lowestFScore) {
        lowestFScore = fScore[p.id];
        current = p;
      }
    }); // 若 openSet 中 fScore 最小的点就是终点

    if (current === goal) {
      // ending condition
      var pathPoints = [];
      reconstructPath(pathPoints, pointById, cameFrom, goal.id);
      return pathPoints;
    }

    removeFrom(openSet, current);
    closedSet.push(current);
    getNeighborPoints(points, current, sBBox, tBBox).forEach(function (neighbor) {
      if (closedSet.indexOf(neighbor) !== -1) {
        return;
      }

      if (openSet.indexOf(neighbor) === -1) {
        openSet.push(neighbor);
      }

      var tentativeGScore = fScore[current.id] + distance(current, neighbor); // + distance(neighbor, goal);

      if (gScore[neighbor.id] && tentativeGScore >= gScore[neighbor.id]) {
        return;
      }

      cameFrom[neighbor.id] = current.id;
      gScore[neighbor.id] = tentativeGScore;
      fScore[neighbor.id] = gScore[neighbor.id] + heuristicCostEstimate(neighbor, goal, start, os, ot);
    });
  } // throw new Error('Cannot find path');


  return [start, goal];
};
export var isBending = function isBending(p0, p1, p2) {
  return !(p0.x === p1.x && p1.x === p2.x || p0.y === p1.y && p1.y === p2.y);
};
export var getBorderRadiusPoints = function getBorderRadiusPoints(p0, p1, p2, r) {
  var d0 = distance(p0, p1);
  var d1 = distance(p2, p1);

  if (d0 < r) {
    r = d0;
  }

  if (d1 < r) {
    r = d1;
  }

  var ps = {
    x: p1.x - r / d0 * (p1.x - p0.x),
    y: p1.y - r / d0 * (p1.y - p0.y)
  };
  var pt = {
    x: p1.x - r / d1 * (p1.x - p2.x),
    y: p1.y - r / d1 * (p1.y - p2.y)
  };
  return [ps, pt];
};
export var getPathWithBorderRadiusByPolyline = function getPathWithBorderRadiusByPolyline(points, borderRadius) {
  // TODO
  var pathSegments = [];
  var startPoint = points[0];
  pathSegments.push("M" + startPoint.x + " " + startPoint.y);
  points.forEach(function (p, i) {
    var p1 = points[i + 1];
    var p2 = points[i + 2];

    if (p1 && p2) {
      if (isBending(p, p1, p2)) {
        var _a = getBorderRadiusPoints(p, p1, p2, borderRadius),
            ps = _a[0],
            pt = _a[1];

        pathSegments.push("L" + ps.x + " " + ps.y);
        pathSegments.push("Q" + p1.x + " " + p1.y + " " + pt.x + " " + pt.y);
        pathSegments.push("L" + pt.x + " " + pt.y);
      } else {
        pathSegments.push("L" + p1.x + " " + p1.y);
      }
    } else if (p1) {
      pathSegments.push("L" + p1.x + " " + p1.y);
    }
  });
  return pathSegments.join('');
};
export var getPolylinePoints = function getPolylinePoints(start, end, sNode, tNode, offset) {
  var sBBox, tBBox;

  if (!sNode || !sNode.getType()) {
    sBBox = getBBoxFromPoint(start);
  } else if (sNode.getType() === 'combo') {
    var sNodeKeyShape = sNode.getKeyShape();
    sBBox = sNodeKeyShape.getCanvasBBox() || getBBoxFromPoint(start);
    sBBox.centerX = (sBBox.minX + sBBox.maxX) / 2;
    sBBox.centerY = (sBBox.minY + sBBox.maxY) / 2;
  } else {
    sBBox = sNode.getBBox();
  }

  if (!tNode || !tNode.getType()) {
    tBBox = getBBoxFromPoint(end);
  } else if (tNode.getType() === 'combo') {
    var tNodeKeyShape = tNode.getKeyShape();
    tBBox = tNodeKeyShape.getCanvasBBox() || getBBoxFromPoint(end);
    tBBox.centerX = (tBBox.minX + tBBox.maxX) / 2;
    tBBox.centerY = (tBBox.minY + tBBox.maxY) / 2;
  } else {
    tBBox = tNode && tNode.getBBox();
  } // if (isBBoxesOverlapping(sBBox, tBBox)) {
  //   // source and target nodes are overlapping
  //   return simplifyPolyline(getSimplePolyline(start, end));
  // }


  var sxBBox = getExpandedBBox(sBBox, offset);
  var txBBox = getExpandedBBox(tBBox, offset); // if (isBBoxesOverlapping(sxBBox, txBBox)) {
  //   // the expanded bounding boxes of source and target nodes are overlapping
  //   return simplifyPolyline(getSimplePolyline(start, end));
  // }

  var sPoint = getExpandedBBoxPoint(sxBBox, start, end);
  var tPoint = getExpandedBBoxPoint(txBBox, end, start);
  var lineBBox = getBBoxFromPoints([sPoint, tPoint]);
  var sMixBBox = mergeBBox(sxBBox, lineBBox);
  var tMixBBox = mergeBBox(txBBox, lineBBox);
  var connectPoints = [];
  connectPoints = connectPoints.concat(getPointsFromBBox(sMixBBox)).concat(getPointsFromBBox(tMixBBox));
  var centerPoint = {
    x: (start.x + end.x) / 2,
    y: (start.y + end.y) / 2
  };
  [lineBBox, sMixBBox, tMixBBox].forEach(function (bbox) {
    connectPoints = connectPoints.concat(getBBoxCrossPointsByPoint(bbox, centerPoint).filter(function (p) {
      return isPointOutsideBBox(p, sxBBox) && isPointOutsideBBox(p, txBBox);
    }));
  });
  [{
    x: sPoint.x,
    y: tPoint.y
  }, {
    x: tPoint.x,
    y: sPoint.y
  }].forEach(function (p) {
    // impossible!!
    if (isPointOutsideBBox(p, sxBBox) && isPointOutsideBBox(p, txBBox) // &&
    // isPointInsideBBox(p, sMixBBox) && isPointInsideBBox(p, tMixBBox)
    ) {
        connectPoints.push(p);
      }
  });
  connectPoints.unshift(sPoint);
  connectPoints.push(tPoint); // filter out dulplicated points in connectPoints

  connectPoints = filterConnectPoints(connectPoints); // , sxBBox, txBBox, outerBBox

  var pathPoints = pathFinder(connectPoints, sPoint, tPoint, sBBox, tBBox, start, end);
  pathPoints.unshift(start);
  pathPoints.push(end);
  return simplifyPolyline(pathPoints);
};