import { __assign, __rest } from "tslib";
import { registerNode, BaseGlobal as Global } from '@antv/g6-core';
import { deepMix } from '@antv/util'; // 带有图标的圆，可用于拓扑图中

registerNode('circle', {
  // 自定义节点时的配置
  options: {
    size: Global.defaultNode.size,
    style: {
      x: 0,
      y: 0,
      stroke: Global.defaultNode.style.stroke,
      fill: Global.defaultNode.style.fill,
      lineWidth: Global.defaultNode.style.lineWidth
    },
    labelCfg: {
      style: {
        fill: Global.nodeLabel.style.fill,
        fontSize: Global.nodeLabel.style.fontSize
      }
    },
    // 节点上左右上下四个方向上的链接circle配置
    linkPoints: {
      top: false,
      right: false,
      bottom: false,
      left: false,
      // circle的大小
      size: Global.defaultNode.linkPoints.size,
      lineWidth: Global.defaultNode.linkPoints.lineWidth,
      fill: Global.defaultNode.linkPoints.fill,
      stroke: Global.defaultNode.linkPoints.stroke
    },
    // 节点中icon配置
    icon: {
      // 是否显示icon，值为 false 则不渲染icon
      show: false,
      // icon的地址，字符串类型
      img: 'https://gw.alipayobjects.com/zos/bmw-prod/5d015065-8505-4e7a-baec-976f81e3c41d.svg',
      width: 20,
      height: 20
    },
    stateStyles: __assign({}, Global.nodeStateStyles)
  },
  shapeType: 'circle',
  // 文本位置
  labelPosition: 'center',
  drawShape: function drawShape(cfg, group) {
    var _a = this.getOptions(cfg).icon,
        defaultIcon = _a === void 0 ? {} : _a;
    var style = this.getShapeStyle(cfg);
    var icon = deepMix({}, defaultIcon, cfg.icon);
    var keyShape = group.addShape('circle', {
      attrs: style,
      className: this.type + "-keyShape",
      draggable: true
    });
    var width = icon.width,
        height = icon.height,
        show = icon.show;

    if (show) {
      group.addShape('image', {
        attrs: __assign({
          x: -width / 2,
          y: -height / 2
        }, icon),
        className: this.type + "-icon",
        name: this.type + "-icon",
        draggable: true
      });
    }

    this.drawLinkPoints(cfg, group);
    return keyShape;
  },

  /**
   * 绘制节点上的LinkPoints
   * @param {Object} cfg data数据配置项
   * @param {Group} group Group实例
   */
  drawLinkPoints: function drawLinkPoints(cfg, group) {
    var _a = this.getOptions(cfg).linkPoints,
        linkPoints = _a === void 0 ? {} : _a;

    var top = linkPoints.top,
        left = linkPoints.left,
        right = linkPoints.right,
        bottom = linkPoints.bottom,
        markSize = linkPoints.size,
        markR = linkPoints.r,
        markStyle = __rest(linkPoints, ["top", "left", "right", "bottom", "size", "r"]);

    var size = this.getSize(cfg);
    var r = size[0] / 2;

    if (left) {
      // left circle
      group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: -r,
          y: 0,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-left',
        name: 'link-point-left',
        isAnchorPoint: true
      });
    }

    if (right) {
      // right circle
      group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: r,
          y: 0,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-right',
        name: 'link-point-right',
        isAnchorPoint: true
      });
    }

    if (top) {
      // top circle
      group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: 0,
          y: -r,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-top',
        name: 'link-point-top',
        isAnchorPoint: true
      });
    }

    if (bottom) {
      // bottom circle
      group.addShape('circle', {
        attrs: __assign(__assign({}, markStyle), {
          x: 0,
          y: r,
          r: markSize / 2 || markR || 5
        }),
        className: 'link-point-bottom',
        name: 'link-point-bottom',
        isAnchorPoint: true
      });
    }
  },

  /**
   * 获取节点的样式，供基于该节点自定义时使用
   * @param {Object} cfg 节点数据模型
   * @return {Object} 节点的样式
   */
  getShapeStyle: function getShapeStyle(cfg) {
    var defaultStyle = this.getOptions(cfg).style;
    var strokeStyle = {
      stroke: cfg.color
    }; // 如果设置了color，则覆盖默认的stroke属性

    var style = deepMix({}, defaultStyle, strokeStyle);
    var size = this.getSize(cfg);
    var r = size[0] / 2;

    var styles = __assign({
      x: 0,
      y: 0,
      r: r
    }, style);

    return styles;
  },
  update: function update(cfg, item) {
    var group = item.getContainer();
    var size = this.getSize(cfg); // 下面这些属性需要覆盖默认样式与目前样式，但若在 cfg 中有指定则应该被 cfg 的相应配置覆盖。

    var strokeStyle = {
      stroke: cfg.color,
      r: size[0] / 2
    }; // 与 getShapeStyle 不同在于，update 时需要获取到当前的 style 进行融合。即新传入的配置项中没有涉及的属性，保留当前的配置。

    var keyShape = item.get('keyShape');
    var style = deepMix({}, keyShape.attr(), strokeStyle, cfg.style);
    this.updateShape(cfg, item, style, true);
    this.updateLinkPoints(cfg, group);
  }
}, 'single-node');