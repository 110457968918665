var EventController =
/** @class */
function () {
  function EventController(graph) {
    this.graph = graph;
    this.destroyed = false;
    this.initEvents();
  }

  return EventController;
}();

export default EventController;