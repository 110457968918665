import * as React from 'react';
/**
 * Work as `componentDidUpdate`
 */

export default function useUpdateEffect(callback, condition) {
  var initRef = React.useRef(false);
  React.useLayoutEffect(function () {
    if (!initRef.current) {
      initRef.current = true;
      return undefined;
    }

    return callback();
  }, condition);
}