import { __assign } from "tslib";
import { deepMix } from '@antv/util';
import Global from '../../global';
import Shape from '../shape'; // 带有图标的圆，可用于拓扑图中

Shape.registerNode('simple-circle', {
  // 自定义节点时的配置
  options: {
    size: Global.defaultNode.size,
    style: {
      x: 0,
      y: 0,
      stroke: Global.defaultNode.style.stroke,
      fill: Global.defaultNode.style.fill,
      lineWidth: Global.defaultNode.style.lineWidth
    },
    labelCfg: {
      style: {
        fill: Global.nodeLabel.style.fill,
        fontSize: Global.nodeLabel.style.fontSize
      }
    },
    stateStyles: __assign({}, Global.nodeStateStyles)
  },
  shapeType: 'simple-circle',
  // 文本位置
  labelPosition: 'center',
  drawShape: function drawShape(cfg, group) {
    var style = this.getShapeStyle(cfg);
    var keyShape = group.addShape('circle', {
      attrs: style,
      className: this.type + "-keyShape",
      draggable: true
    });
    return keyShape;
  },

  /**
   * 获取节点的样式，供基于该节点自定义时使用
   * @param {Object} cfg 节点数据模型
   * @return {Object} 节点的样式
   */
  getShapeStyle: function getShapeStyle(cfg) {
    var defaultStyle = this.getOptions(cfg).style;
    var strokeStyle = {
      stroke: cfg.color
    }; // 如果设置了color，则覆盖默认的stroke属性

    var style = deepMix({}, defaultStyle, strokeStyle);
    var size = this.getSize(cfg);
    var r = size[0] / 2;

    var styles = __assign({
      x: 0,
      y: 0,
      r: r
    }, style);

    return styles;
  },
  update: function update(cfg, item) {
    var size = this.getSize(cfg); // 下面这些属性需要覆盖默认样式与目前样式，但若在 cfg 中有指定则应该被 cfg 的相应配置覆盖。

    var strokeStyle = {
      stroke: cfg.color,
      r: size[0] / 2
    }; // 与 getShapeStyle 不同在于，update 时需要获取到当前的 style 进行融合。即新传入的配置项中没有涉及的属性，保留当前的配置。

    var keyShape = item.get('keyShape');
    var style = deepMix({}, keyShape.attr(), strokeStyle, cfg.style);
    this.updateShape(cfg, item, style, true);
  }
}, 'single-node');