import { __assign } from "tslib";
import * as MathUtil from './math';
import * as GraphicUtil from './graphic';
import * as PathUtil from './path';
import * as BaseUtil from './base';
import { ext, mat3 } from '@antv/matrix-util';
var transform = ext.transform;

var Util = __assign(__assign(__assign(__assign(__assign({}, BaseUtil), GraphicUtil), PathUtil), MathUtil), {
  transform: transform,
  mat3: mat3
});

export default Util;