function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import { __extends } from "tslib";
import { AbstractLayout } from '@antv/g6-core';
import { Layout } from '../../layout';
import { LayoutWorker } from '../../layout/worker/layout.worker';
import { LAYOUT_MESSAGE } from '../../layout/worker/layoutConst';
import { gpuDetector } from '../../util/gpu';
import { mix } from '@antv/util'; // eslint-disable-next-line @typescript-eslint/no-implied-eval

var mockRaf = function mockRaf(cb) {
  return setTimeout(cb, 16);
};

var mockCaf = function mockCaf(reqId) {
  return clearTimeout(reqId);
};

var helper = {
  // pollyfill
  requestAnimationFrame: function requestAnimationFrame(callback) {
    var fn = typeof window !== 'undefined' ? window.requestAnimationFrame || window.webkitRequestAnimationFrame || mockRaf : mockRaf;
    return fn(callback);
  },
  cancelAnimationFrame: function cancelAnimationFrame(requestId) {
    var fn = typeof window !== 'undefined' ? window.cancelAnimationFrame || window.webkitCancelAnimationFrame || mockCaf : mockCaf;
    return fn(requestId);
  }
};
var GPULayoutNames = ['fruchterman', 'gForce'];

var LayoutController =
/** @class */
function (_super) {
  __extends(LayoutController, _super); // the configurations of the layout
  // private layoutCfg: any; // LayoutOptions
  // the type name of the layout
  // private layoutType: string;
  // private data: GraphData;
  // private layoutMethod: typeof Layout;


  function LayoutController(graph) {
    var _this = _super.call(this, graph) || this;

    _this.graph = graph;
    _this.layoutCfg = graph.get('layout') || {};
    _this.layoutType = _this.layoutCfg.type;
    _this.worker = null;
    _this.workerData = {};

    _this.initLayout();

    return _this;
  } // eslint-disable-next-line class-methods-use-this


  LayoutController.prototype.initLayout = function () {// no data before rendering
  }; // get layout worker and create one if not exists


  LayoutController.prototype.getWorker = function () {
    if (this.worker) {
      return this.worker;
    }

    if (typeof Worker === 'undefined') {
      // 如果当前浏览器不支持 web worker，则不使用 web worker
      console.warn('Web worker is not supported in current browser.');
      this.worker = null;
    } else {
      this.worker = LayoutWorker(this.layoutCfg.workerScriptURL);
    }

    return this.worker;
  }; // stop layout worker


  LayoutController.prototype.stopWorker = function () {
    var workerData = this.workerData;

    if (!this.worker) {
      return;
    }

    this.worker.terminate();
    this.worker = null; // 重新开始新的布局之前，先取消之前布局的requestAnimationFrame。

    if (workerData.requestId) {
      helper.cancelAnimationFrame(workerData.requestId);
      workerData.requestId = null;
    }

    if (workerData.requestId2) {
      helper.cancelAnimationFrame(workerData.requestId2);
      workerData.requestId2 = null;
    }
  };
  /**
   * @param {function} success callback
   * @return {boolean} 是否使用web worker布局
   */


  LayoutController.prototype.layout = function (success) {
    var _this = this;

    var graph = this.graph;
    this.data = this.setDataFromGraph();
    var nodes = this.data.nodes;

    if (!nodes) {
      return false;
    }

    var width = graph.get('width');
    var height = graph.get('height');
    var layoutCfg = {};
    Object.assign(layoutCfg, {
      width: width,
      height: height,
      center: [width / 2, height / 2]
    }, this.layoutCfg);
    this.layoutCfg = layoutCfg;
    var hasLayoutType = !!this.layoutType;
    var layoutMethod = this.layoutMethod;

    if (layoutMethod) {
      layoutMethod.destroy();
    }

    graph.emit('beforelayout');
    var allHavePos = this.initPositions(layoutCfg.center, nodes);
    var layoutType = this.layoutType;
    var isGPU = false; // 防止用户直接用 -gpu 结尾指定布局

    if (layoutType && layoutType.split('-')[1] === 'gpu') {
      layoutType = layoutType.split('-')[0];
      layoutCfg.gpuEnabled = true;
    } // 若用户指定开启 gpu，且当前浏览器支持 webgl，且该算法存在 GPU 版本（目前仅支持 fruchterman 和 gForce），使用 gpu 版本的布局


    if (layoutType && layoutCfg.gpuEnabled) {
      var enableGPU = true; // 打开下面语句将会导致 webworker 报找不到 window

      if (!gpuDetector().webgl) {
        console.warn("Your browser does not support webGL or GPGPU. The layout will run in CPU.");
        enableGPU = false;
      }

      if (!this.hasGPUVersion(layoutType)) {
        console.warn("The '" + layoutType + "' layout does not support GPU calculation for now, it will run in CPU.");
        enableGPU = false;
      }

      if (enableGPU) {
        layoutType = layoutType + "-gpu"; // layoutCfg.canvasEl = this.graph.get('canvas').get('el');

        isGPU = true;
      }
    }

    this.isGPU = isGPU;
    this.stopWorker();

    if (layoutCfg.workerEnabled && this.layoutWithWorker(this.data, success)) {
      // 如果启用布局web worker并且浏览器支持web worker，用web worker布局。否则回退到不用web worker布局。
      return true;
    }

    var isForce = layoutType === 'force' || layoutType === 'g6force' || layoutType === 'gForce'; // 所有布局挂载 onLayoutEnd, 在布局结束后依次执行：
    // 执行用户自定义 onLayoutEnd，触发 afterlayout、更新节点位置、fitView/fitCenter、触发 afterrender

    var onLayoutEnd = layoutCfg.onLayoutEnd,
        layoutEndFormatted = layoutCfg.layoutEndFormatted;

    if (!layoutEndFormatted) {
      layoutCfg.layoutEndFormatted = true;

      layoutCfg.onLayoutEnd = function () {
        // 执行用户自定义 onLayoutEnd
        if (onLayoutEnd) {
          onLayoutEnd();
        } // 触发 afterlayout


        graph.emit('afterlayout'); // 更新节点位置

        _this.refreshLayout(); // 由 graph 传入的，控制 fitView、fitCenter，并触发 afterrender

      };
    }

    if (isForce) {
      var onTick_1 = layoutCfg.onTick;

      var tick = function tick() {
        if (onTick_1) {
          onTick_1();
        }

        graph.refreshPositions();
      };

      layoutCfg.tick = tick;
    } else if (this.layoutType === 'comboForce') {
      layoutCfg.comboTrees = graph.get('comboTrees');
    }

    var enableTick = false;

    if (layoutType !== undefined) {
      try {
        layoutMethod = new Layout[layoutType](layoutCfg);
      } catch (e) {
        console.warn("The layout method: '" + layoutType + "' does not exist! Please specify it first.");
        return false;
      } // 是否需要迭代的方式完成布局。这里是来自布局对象的实例属性，是由布局的定义者在布局类定义的。


      enableTick = layoutMethod.enableTick;

      if (enableTick) {
        var onTick_2 = layoutCfg.onTick;

        var tick = function tick() {
          if (onTick_2) {
            onTick_2();
          }

          graph.refreshPositions();
        };

        layoutMethod.tick = tick;
      }

      layoutMethod.init(this.data); // 若存在节点没有位置信息，且没有设置 layout，在 initPositions 中 random 给出了所有节点的位置，不需要再次执行 random 布局
      // 所有节点都有位置信息，且指定了 layout，则执行布局（代表不是第一次进行布局）

      if (hasLayoutType) {
        graph.emit('beginlayout');
        layoutMethod.execute();
        if (layoutMethod.isCustomLayout && layoutCfg.onLayoutEnd && !isForce) layoutCfg.onLayoutEnd(); // 在执行 execute 后立即执行 success，且在 timeBar 中有 throttle，可以防止 timeBar 监听 afterrender 进行 changeData 后 layout，从而死循环
        // 对于 force 一类布局完成后的 fitView 需要用户自己在 onLayoutEnd 中配置

        if (success) success();
      }

      this.layoutMethod = layoutMethod;
    } // 若没有配置 layout，也需要更新画布


    if (!this.layoutMethod && success) {
      graph.refreshPositions();
      success();
    }

    return false;
  };
  /**
   * layout with web worker
   * @param {object} data graph data
   * @param {function} success callback function
   * @return {boolean} 是否支持web worker
   */


  LayoutController.prototype.layoutWithWorker = function (data, success) {
    var _this = this;

    var nodes = data.nodes,
        edges = data.edges;

    var _a = this,
        layoutCfg = _a.layoutCfg,
        graph = _a.graph,
        isGPU = _a.isGPU;

    var worker = this.getWorker(); // 每次worker message event handler调用之间的共享数据，会被修改。

    var workerData = this.workerData;

    if (!worker) {
      return false;
    }

    workerData.requestId = null;
    workerData.requestId2 = null;
    workerData.currentTick = null;
    workerData.currentTickData = null;
    graph.emit('beforelayout');
    var offScreenCanvas = document.createElement('canvas');
    var gpuWorkerAbility = isGPU && typeof window !== 'undefined' && // eslint-disable-next-line @typescript-eslint/dot-notation
    window.navigator && !navigator["gpu"] && // WebGPU 还不支持 OffscreenCanvas
    'OffscreenCanvas' in window && 'transferControlToOffscreen' in offScreenCanvas; // NOTE: postMessage的message参数里面不能包含函数，否则postMessage会报错，
    // 例如：'function could not be cloned'。
    // 详情参考：https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
    // 所以这里需要把过滤layoutCfg里的函数字段过滤掉。

    var filteredLayoutCfg = filterObject(layoutCfg, function (value) {
      return typeof value !== 'function';
    });

    if (!gpuWorkerAbility) {
      worker.postMessage({
        type: LAYOUT_MESSAGE.RUN,
        nodes: nodes,
        edges: edges,
        layoutCfg: filteredLayoutCfg
      });
    } else {
      var offscreen = offScreenCanvas.transferControlToOffscreen(); // filteredLayoutCfg.canvas = offscreen;

      filteredLayoutCfg.type = filteredLayoutCfg.type + "-gpu";
      worker.postMessage({
        type: LAYOUT_MESSAGE.GPURUN,
        nodes: nodes,
        edges: edges,
        layoutCfg: filteredLayoutCfg,
        canvas: offscreen
      }, [offscreen]);
    }

    worker.onmessage = function (event) {
      _this.handleWorkerMessage(event, data, success);
    };

    return true;
  }; // success callback will be called when updating graph positions for the first time.


  LayoutController.prototype.handleWorkerMessage = function (event, data, success) {
    var _a = this,
        graph = _a.graph,
        workerData = _a.workerData,
        layoutCfg = _a.layoutCfg;

    var eventData = event.data;
    var type = eventData.type;

    var onTick = function onTick() {
      if (layoutCfg.onTick) {
        layoutCfg.onTick();
      }
    };

    var onLayoutEnd = function onLayoutEnd() {
      if (layoutCfg.onLayoutEnd) {
        layoutCfg.onLayoutEnd();
      }

      graph.emit('afterlayout');
    };

    switch (type) {
      case LAYOUT_MESSAGE.TICK:
        workerData.currentTick = eventData.currentTick;
        workerData.currentTickData = eventData;

        if (!workerData.requestId) {
          workerData.requestId = helper.requestAnimationFrame(function requestId() {
            updateLayoutPosition(data, eventData);
            graph.refreshPositions();
            onTick();

            if (eventData.currentTick === 1 && success) {
              success();
            }

            if (eventData.currentTick === eventData.totalTicks) {
              // 如果是最后一次tick
              onLayoutEnd();
            } else if (workerData.currentTick === eventData.totalTicks) {
              // 注意这里workerData.currentTick可能已经不再是前面赋值时候的值了，
              // 因为在requestAnimationFrame等待时间里，可能产生新的tick。
              // 如果当前tick不是最后一次tick，并且所有的tick消息都已发出来了，那么需要用最后一次tick的数据再刷新一次。
              workerData.requestId2 = helper.requestAnimationFrame(function requestId2() {
                updateLayoutPosition(data, workerData.currentTickData);
                graph.refreshPositions();
                workerData.requestId2 = null;
                onTick();
                onLayoutEnd();
              });
            }

            workerData.requestId = null;
          });
        }

        break;

      case LAYOUT_MESSAGE.END:
        // 如果没有tick消息（非力导布局）
        if (workerData.currentTick == null) {
          updateLayoutPosition(data, eventData);
          this.refreshLayout(); // 非力导布局，没有tick消息，只有end消息，所以需要执行一次回调。

          if (success) {
            success();
          }

          graph.emit('afterlayout');
        }

        break;

      case LAYOUT_MESSAGE.GPUEND:
        // 如果没有tick消息（非力导布局）
        if (workerData.currentTick == null) {
          updateGPUWorkerLayoutPosition(data, eventData);
          this.refreshLayout(); // 非力导布局，没有tick消息，只有end消息，所以需要执行一次回调。

          if (success) {
            success();
          }

          graph.emit('afterlayout');
        }

        break;

      case LAYOUT_MESSAGE.ERROR:
        console.warn('Web-Worker layout error!', eventData.message);
        break;

      default:
        break;
    }
  }; // 更新布局参数


  LayoutController.prototype.updateLayoutCfg = function (cfg) {
    var _a = this,
        graph = _a.graph,
        layoutMethod = _a.layoutMethod,
        layoutType = _a.layoutType,
        layoutCfg = _a.layoutCfg;

    this.layoutType = cfg.type;

    if (!layoutMethod || layoutMethod.destroyed) {
      this.layoutCfg = mix({}, layoutCfg, cfg);
      this.layout();
      return;
    }

    this.data = this.setDataFromGraph();
    this.stopWorker();

    if (cfg.workerEnabled && this.layoutWithWorker(this.data, null)) {
      // 如果启用布局web worker并且浏览器支持web worker，用web worker布局。否则回退到不用web worker布局。
      return;
    }

    layoutMethod.init(this.data);
    layoutMethod.updateCfg(cfg);
    graph.emit('beforelayout');
    layoutMethod.execute();
    if (layoutMethod.isCustomLayout && layoutCfg.onLayoutEnd) layoutCfg.onLayoutEnd();
  };

  LayoutController.prototype.hasGPUVersion = function (layoutName) {
    var length = GPULayoutNames.length;

    for (var i = 0; i < length; i++) {
      if (GPULayoutNames[i] === layoutName) return true;
    }

    return false;
  };

  LayoutController.prototype.destroy = function () {
    var layoutMethod = this.layoutMethod;

    if (layoutMethod) {
      layoutMethod.destroy();
      layoutMethod.destroyed = true;
    }

    var worker = this.worker;

    if (worker) {
      worker.terminate();
      this.worker = null;
    }

    this.destroyed = true;
    this.graph.set('layout', undefined);
    this.layoutCfg = undefined;
    this.layoutType = undefined;
    this.layoutMethod = undefined;
    this.graph = null;
  };

  return LayoutController;
}(AbstractLayout);

export default LayoutController;

function updateLayoutPosition(data, layoutData) {
  var nodes = data.nodes;
  var layoutNodes = layoutData.nodes;
  var nodeLength = nodes.length;

  for (var i = 0; i < nodeLength; i++) {
    var node = nodes[i];
    node.x = layoutNodes[i].x;
    node.y = layoutNodes[i].y;
  }
}

function filterObject(collection, callback) {
  var result = {};

  if (collection && _typeof(collection) === 'object') {
    Object.keys(collection).forEach(function (key) {
      if (collection.hasOwnProperty(key) && callback(collection[key])) {
        result[key] = collection[key];
      }
    });
    return result;
  }

  return collection;
}

function updateGPUWorkerLayoutPosition(data, layoutData) {
  var nodes = data.nodes;
  var vertexEdgeData = layoutData.vertexEdgeData;
  var nodeLength = nodes.length;

  for (var i = 0; i < nodeLength; i++) {
    var node = nodes[i];
    var x = vertexEdgeData[4 * i];
    var y = vertexEdgeData[4 * i + 1];
    node.x = x;
    node.y = y;
  }
}