import { __assign } from "tslib";
import { mix, each, isArray, isString } from '@antv/util';
import { registerEdge, Util, BaseGlobal as Global } from '@antv/g6-core';
import { getPathWithBorderRadiusByPolyline, getPolylinePoints } from './polyline-util';
import { pathFinder } from './router'; // 折线

registerEdge('polyline', {
  options: {
    color: Global.defaultEdge.color,
    size: Global.defaultEdge.size,
    style: {
      radius: 0,
      offset: 15,
      x: 0,
      y: 0,
      stroke: Global.defaultEdge.style.stroke,
      lineAppendWidth: Global.defaultEdge.style.lineAppendWidth
    },
    // 文本样式配置
    labelCfg: {
      style: {
        fill: Global.edgeLabel.style.fill,
        fontSize: Global.edgeLabel.style.fontSize
      }
    },
    routeCfg: {
      obstacles: [],
      maxAllowedDirectionChange: Math.PI,
      maximumLoops: 500,
      gridSize: 10 // 指定精度

    },
    stateStyles: __assign({}, Global.edgeStateStyles)
  },
  shapeType: 'polyline',
  // 文本位置
  labelPosition: 'center',
  drawShape: function drawShape(cfg, group) {
    var shapeStyle = this.getShapeStyle(cfg);
    if (shapeStyle.radius === 0) delete shapeStyle.radius;
    var keyShape = group.addShape('path', {
      className: 'edge-shape',
      name: 'edge-shape',
      attrs: shapeStyle
    });
    return keyShape;
  },
  getShapeStyle: function getShapeStyle(cfg) {
    var defaultStyle = this.options.style;
    var strokeStyle = {
      stroke: cfg.color
    };
    var style = mix({}, defaultStyle, strokeStyle, cfg.style);
    cfg = this.getPathPoints(cfg);
    this.radius = style.radius;
    this.offset = style.offset;
    var startPoint = cfg.startPoint,
        endPoint = cfg.endPoint;
    var controlPoints = this.getControlPoints(cfg);
    var points = [startPoint]; // 添加起始点
    // 添加控制点

    if (controlPoints) {
      points = points.concat(controlPoints);
    } // 添加结束点


    points.push(endPoint);
    var source = cfg.sourceNode;
    var target = cfg.targetNode;
    var radius = style.radius;
    var defaultRouteCfg = this.options.routeCfg;
    var routeCfg = mix({}, defaultRouteCfg, cfg.routeCfg);
    routeCfg.offset = style.offset;
    var path = this.getPath(points, source, target, radius, routeCfg);

    if (isArray(path) && path.length <= 1 || isString(path) && path.indexOf('L') === -1) {
      path = 'M0 0, L0 0';
    }

    if (isNaN(startPoint.x) || isNaN(startPoint.y) || isNaN(endPoint.x) || isNaN(endPoint.y)) {
      path = 'M0 0, L0 0';
    }

    var attrs = mix({}, Global.defaultEdge.style, style, {
      lineWidth: cfg.size,
      path: path
    });
    return attrs;
  },
  updateShapeStyle: function updateShapeStyle(cfg, item) {
    var group = item.getContainer();
    if (!item.isVisible()) return;
    var strokeStyle = {
      stroke: cfg.color
    };
    var shape = group.find(function (element) {
      return element.get('className') === 'edge-shape';
    }) || item.getKeyShape();
    var size = cfg.size;
    cfg = this.getPathPoints(cfg);
    var startPoint = cfg.startPoint,
        endPoint = cfg.endPoint;
    var controlPoints = this.getControlPoints(cfg); // || cfg.controlPoints;

    var points = [startPoint]; // 添加起始点
    // 添加控制点

    if (controlPoints) {
      points = points.concat(controlPoints);
    } // 添加结束点


    points.push(endPoint);
    var currentAttr = shape.attr();
    var previousStyle = mix({}, strokeStyle, currentAttr, cfg.style);
    var source = cfg.sourceNode;
    var target = cfg.targetNode;
    var radius = previousStyle.radius;
    var defaultRouteCfg = this.options.routeCfg;
    var routeCfg = mix({}, defaultRouteCfg, cfg.routeCfg);
    routeCfg.offset = previousStyle.offset;
    var path = this.getPath(points, source, target, radius, routeCfg);

    if (isArray(path) && path.length <= 1 || isString(path) && path.indexOf('L') === -1) {
      path = 'M0 0, L0 0';
    }

    if (isNaN(startPoint.x) || isNaN(startPoint.y) || isNaN(endPoint.x) || isNaN(endPoint.y)) {
      path = 'M0 0, L0 0';
    }

    if (currentAttr.endArrow && previousStyle.endArrow === false) {
      cfg.style.endArrow = {
        path: ''
      };
    }

    if (currentAttr.startArrow && previousStyle.startArrow === false) {
      cfg.style.startArrow = {
        path: ''
      };
    }

    var style = mix(strokeStyle, shape.attr(), {
      lineWidth: size,
      path: path
    }, cfg.style);

    if (shape) {
      shape.attr(style);
    }
  },
  getPath: function getPath(points, source, target, radius, routeCfg) {
    var offset = routeCfg.offset,
        simple = routeCfg.simple; // 指定了控制点

    if (!offset || points.length > 2) {
      if (radius) {
        return getPathWithBorderRadiusByPolyline(points, radius);
      }

      var pathArray_1 = [];
      each(points, function (point, index) {
        if (index === 0) {
          pathArray_1.push(['M', point.x, point.y]);
        } else {
          pathArray_1.push(['L', point.x, point.y]);
        }
      });
      return pathArray_1;
    } // 未指定控制点


    var polylinePoints = simple ? getPolylinePoints(points[points.length - 1], points[0], target, source, offset) : pathFinder(points[0], points[points.length - 1], source, target, routeCfg);

    if (radius) {
      var res_1 = getPathWithBorderRadiusByPolyline(polylinePoints, radius);
      return res_1;
    }

    if (!polylinePoints || !polylinePoints.length) return 'M0 0, L0 0';
    var res = Util.pointsToPolygon(polylinePoints);
    return res;
  }
}, 'single-edge');