import Grid from './grid';
import Menu from './menu';
import Minimap from './minimap';
import Bundling from './bundling';
import Fisheye from './fisheye';
import ToolBar from './toolBar';
import Tooltip from './tooltip';
import TimeBar from './timeBar';
import ImageMinimap from './imageMinimap';
import EdgeFilterLens from './edgeFilterLens';
import PluginBase from './base';
export { PluginBase, Menu, Grid, Minimap, Bundling, ToolBar, Tooltip, Fisheye, TimeBar, ImageMinimap, EdgeFilterLens };
var Plugin = {
  PluginBase: PluginBase,
  Menu: Menu,
  Grid: Grid,
  Minimap: Minimap,
  Bundling: Bundling,
  ToolBar: ToolBar,
  Tooltip: Tooltip,
  Fisheye: Fisheye,
  TimeBar: TimeBar,
  ImageMinimap: ImageMinimap,
  EdgeFilterLens: EdgeFilterLens
};
export default Plugin;