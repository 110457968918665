import { isString, isNumber, isNil, isArray } from '@antv/util';
import { G6GraphEvent } from '../interface/behavior';
export var uniqueId = function uniqueId(type) {
  return type + "-" + Math.random() + Date.now();
};
/**
 * turn padding into [top, right, bottom, right]
 * @param  {Number|Array} padding input padding
 * @return {array} output
 */

export var formatPadding = function formatPadding(padding) {
  var top = 0;
  var left = 0;
  var right = 0;
  var bottom = 0;

  if (isNumber(padding)) {
    top = left = right = bottom = padding;
  } else if (isString(padding)) {
    var intPadding = parseInt(padding, 10);
    top = left = right = bottom = intPadding;
  } else if (isArray(padding)) {
    top = padding[0];
    right = !isNil(padding[1]) ? padding[1] : padding[0];
    bottom = !isNil(padding[2]) ? padding[2] : padding[0];
    left = !isNil(padding[3]) ? padding[3] : right;
  }

  return [top, right, bottom, left];
};
/**
 * clone event
 * @param e
 */

export var cloneEvent = function cloneEvent(e) {
  var event = new G6GraphEvent(e.type, e);
  event.clientX = e.clientX;
  event.clientY = e.clientY;
  event.x = e.x;
  event.y = e.y;
  event.target = e.target;
  event.currentTarget = e.currentTarget;
  event.bubbles = true;
  event.item = e.item;
  return event;
};
/**
 * 判断 viewport 是否改变，通过和单位矩阵对比
 * @param matrix Viewport 的 Matrix
 */

export var isViewportChanged = function isViewportChanged(matrix) {
  // matrix 为 null， 则说明没有变化
  if (!matrix) {
    return false;
  }

  var MATRIX_LEN = 9;
  var ORIGIN_MATRIX = [1, 0, 0, 0, 1, 0, 0, 0, 1];

  for (var i = 0; i < MATRIX_LEN; i++) {
    if (matrix[i] !== ORIGIN_MATRIX[i]) {
      return true;
    }
  }

  return false;
};
export var isNaN = function isNaN(input) {
  return Number.isNaN(Number(input));
};
/**
 * 计算一组 Item 的 BBox
 * @param items 选中的一组Item，可以是 node 或 combo
 */

export var calculationItemsBBox = function calculationItemsBBox(items) {
  var minx = Infinity;
  var maxx = -Infinity;
  var miny = Infinity;
  var maxy = -Infinity; // 获取已节点的所有最大最小x y值

  for (var i = 0; i < items.length; i++) {
    var element = items[i];
    var bbox = element.getBBox();
    var minX = bbox.minX,
        minY = bbox.minY,
        maxX = bbox.maxX,
        maxY = bbox.maxY;

    if (minX < minx) {
      minx = minX;
    }

    if (minY < miny) {
      miny = minY;
    }

    if (maxX > maxx) {
      maxx = maxX;
    }

    if (maxY > maxy) {
      maxy = maxY;
    }
  }

  var x = Math.floor(minx);
  var y = Math.floor(miny);
  var width = Math.ceil(maxx) - Math.floor(minx);
  var height = Math.ceil(maxy) - Math.floor(miny);
  return {
    x: x,
    y: y,
    width: width,
    height: height,
    minX: minx,
    minY: miny,
    maxX: maxx,
    maxY: maxy
  };
};
/**
 * 若 edges 中存在两端点相同的边，使用 quadratic 边并自动计算 curveOffset 使它们不相互重叠
 * 文档: https://g6.antv.vision/en/docs/api/Util
 * @param edges 边数据集合
 * @param offsetDiff 相邻两边的 offset 之差
 * @param multiEdgeType
 * @param singleEdgeType
 * @param loopEdgeType
 */

export var processParallelEdges = function processParallelEdges(edges, offsetDiff, multiEdgeType, singleEdgeType, loopEdgeType) {
  if (offsetDiff === void 0) {
    offsetDiff = 15;
  }

  if (multiEdgeType === void 0) {
    multiEdgeType = 'quadratic';
  }

  if (singleEdgeType === void 0) {
    singleEdgeType = undefined;
  }

  if (loopEdgeType === void 0) {
    loopEdgeType = undefined;
  }

  var len = edges.length;
  var cod = offsetDiff * 2;
  var loopPosition = ['top', 'top-right', 'right', 'bottom-right', 'bottom', 'bottom-left', 'left', 'top-left'];
  var edgeMap = {};
  var tags = [];
  var reverses = {};

  for (var i = 0; i < len; i++) {
    var edge = edges[i];
    var source = edge.source,
        target = edge.target;
    var sourceTarget = source + "-" + target;
    if (tags[i]) continue;

    if (!edgeMap[sourceTarget]) {
      edgeMap[sourceTarget] = [];
    }

    tags[i] = true;
    edgeMap[sourceTarget].push(edge);

    for (var j = 0; j < len; j++) {
      if (i === j) continue;
      var sedge = edges[j];
      var src = sedge.source;
      var dst = sedge.target; // 两个节点之间共同的边
      // 第一条的source = 第二条的target
      // 第一条的target = 第二条的source

      if (!tags[j]) {
        if (source === dst && target === src) {
          edgeMap[sourceTarget].push(sedge);
          tags[j] = true;
          reverses[src + "|" + dst + "|" + (edgeMap[sourceTarget].length - 1)] = true;
        } else if (source === src && target === dst) {
          edgeMap[sourceTarget].push(sedge);
          tags[j] = true;
        }
      }
    }
  }

  for (var key in edgeMap) {
    var arcEdges = edgeMap[key];
    var length_1 = arcEdges.length;

    for (var k = 0; k < length_1; k++) {
      var current = arcEdges[k];

      if (current.source === current.target) {
        if (loopEdgeType) current.type = loopEdgeType; // 超过8条自环边，则需要重新处理

        current.loopCfg = {
          position: loopPosition[k % 8],
          dist: Math.floor(k / 8) * 20 + 50
        };
        continue;
      }

      if (length_1 === 1 && singleEdgeType && current.source !== current.target) {
        current.type = singleEdgeType;
        continue;
      }

      current.type = multiEdgeType;
      var sign = (k % 2 === 0 ? 1 : -1) * (reverses[current.source + "|" + current.target + "|" + k] ? -1 : 1);

      if (length_1 % 2 === 1) {
        current.curveOffset = sign * Math.ceil(k / 2) * cod;
      } else {
        current.curveOffset = sign * (Math.floor(k / 2) * cod + offsetDiff);
      }
    }
  }

  return edges;
};