var __spreadArray = this && this.__spreadArray || function (to, from) {
  for (var i = 0, il = from.length, j = to.length; i < il; i++, j++) {
    to[j] = from[i];
  }

  return to;
};

import * as pathUtil from '@antv/path-util';
import { Category, Linear } from '@antv/scale';
import { map, each, isEqual, head, isArray } from '@antv/util';
/**
 * 点数组转 path
 * @param points
 */

function pointsToPath(points) {
  return map(points, function (p, idx) {
    var command = idx === 0 ? 'M' : 'L';
    var x = p[0],
        y = p[1];
    return [command, x, y];
  });
}
/**
 * 将点连接成路径 path
 * @param points
 */


export function getLinePath(points) {
  return pointsToPath(points);
}
/**
 * 将点连成平滑的曲线
 * @param points
 */

export function getSmoothLinePath(points) {
  if (points.length <= 2) {
    // 两点以内直接绘制成路径
    return getLinePath(points);
  }

  var data = [];
  each(points, function (p) {
    // 当前点和上一个点一样的时候，忽略掉
    if (!isEqual(p, data.slice(data.length - 2))) {
      data.push(p[0], p[1]);
    }
  });
  var path = pathUtil.catmullRom2Bezier(data, false);

  var _a = head(points),
      x = _a[0],
      y = _a[1];

  path.unshift(['M', x, y]);
  return path;
}
/**
 * 将数据转成 path，利用 scale 的归一化能力
 * @param data
 * @param width
 * @param height
 * @param smooth
 */

export function dataToPath(data, width, height, smooth) {
  if (smooth === void 0) {
    smooth = true;
  } // 利用 scale 来获取 y 上的映射


  var y = new Linear({
    values: data
  });
  var x = new Category({
    values: map(data, function (v, idx) {
      return idx;
    })
  });
  var points = map(data, function (v, idx) {
    return [x.scale(idx) * width, height - y.scale(v) * height];
  });
  return smooth ? getSmoothLinePath(points) : getLinePath(points);
}
export function dataToRectPath(data, width, height) {
  // 利用 scale 来获取 y 上的映射
  var y = new Linear({
    values: data
  });
  var x = new Category({
    values: map(data, function (v, idx) {
      return idx;
    })
  });
  var points = map(data, function (v, idx) {
    return [x.scale(idx) * width, height - y.scale(v) * height];
  });
  var rectPoints = [];

  for (var i = 0; i < points.length; i++) {
    var point = points[i];
    var param = {
      x: point[0],
      y: point[1],
      y0: height,
      size: 5
    };
    var rectPoint = getRectPoints(param);
    rectPoints.push.apply(rectPoints, rectPoint);
  }

  return getRectPath(rectPoints);
}
/**
 * 获得 area 面积的横向连接线的 px 位置
 * @param data
 * @param width
 * @param height
 */

export function getAreaLineY(data, height) {
  var y = new Linear({
    values: data
  });
  var lineY = Math.max(0, y.min);
  return height - y.scale(lineY) * height;
}
/**
 * 线 path 转 area path
 * @param path
 * @param width
 * @param height
 */

export function linePathToAreaPath(path, width, height, data) {
  var areaPath = __spreadArray([], path);

  var lineYPx = getAreaLineY(data, height);
  areaPath.push(['L', width, lineYPx]);
  areaPath.push(['L', 0, lineYPx]);
  areaPath.push(['Z']);
  return areaPath;
}
/**
 * @ignore
 * 根据数据点生成矩形的四个关键点
 * @param pointInfo 数据点信息
 * @returns rect points 返回矩形四个顶点信息
 */

export function getRectPoints(pointInfo) {
  var x = pointInfo.x,
      y = pointInfo.y,
      y0 = pointInfo.y0,
      size = pointInfo.size; // 有 4 种情况，
  // 1. x, y 都不是数组
  // 2. y是数组，x不是
  // 3. x是数组，y不是
  // 4. x, y 都是数组

  var yMin;
  var yMax;

  if (isArray(y)) {
    yMin = y[0], yMax = y[1];
  } else {
    yMin = y0;
    yMax = y;
  }

  var xMin;
  var xMax;

  if (isArray(x)) {
    xMin = x[0], xMax = x[1];
  } else {
    xMin = x - size / 2;
    xMax = x + size / 2;
  }

  var points = [{
    x: xMin,
    y: yMin
  }, {
    x: xMin,
    y: yMax
  }]; // 矩形的四个关键点，结构如下（左下角顺时针连接）
  // 1 ---- 2
  // |      |
  // 0 ---- 3

  points.push({
    x: xMax,
    y: yMax
  }, {
    x: xMax,
    y: yMin
  });
  return points;
}
/**
 * @ignore
 * 根据矩形关键点绘制 path
 * @param points 关键点数组
 * @param isClosed path 是否需要闭合
 * @returns 返回矩形的 path
 */

export function getRectPath(points, isClosed) {
  if (isClosed === void 0) {
    isClosed = true;
  }

  var path = [];
  var firstPoint = points[0];
  path.push(['M', firstPoint.x, firstPoint.y]);

  for (var i = 1, len = points.length; i < len; i++) {
    path.push(['L', points[i].x, points[i].y]);
  } // 对于 shape="line" path 不应该闭合，否则会造成 lineCap 绘图属性失效


  if (isClosed) {
    path.push(['L', firstPoint.x, firstPoint.y]); // 需要闭合

    path.push(['z']);
  }

  return path;
}