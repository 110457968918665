export * from '../interface'; // Behavior type file

export var G6Event;

(function (G6Event) {
  // common events
  G6Event["CLICK"] = "click";
  G6Event["DBLCLICK"] = "dblclick";
  G6Event["MOUSEDOWN"] = "mousedown";
  G6Event["MOUDEUP"] = "mouseup";
  G6Event["CONTEXTMENU"] = "contextmenu";
  G6Event["MOUSEENTER"] = "mouseenter";
  G6Event["MOUSEOUT"] = "mouseout";
  G6Event["MOUSEOVER"] = "mouseover";
  G6Event["MOUSEMOVE"] = "mousemove";
  G6Event["MOUSELEAVE"] = "mouseleave";
  G6Event["DRAGSTART"] = "dragstart";
  G6Event["DRAGEND"] = "dragend";
  G6Event["DRAG"] = "drag";
  G6Event["DRAGENTER"] = "dragenter";
  G6Event["DRAGLEAVE"] = "dragleave";
  G6Event["DRAGOVER"] = "dragover";
  G6Event["DRAGOUT"] = "dragout";
  G6Event["DDROP"] = "drop";
  G6Event["KEYUP"] = "keyup";
  G6Event["KEYDOWN"] = "keydown";
  G6Event["WHEEL"] = "wheel";
  G6Event["FOCUS"] = "focus";
  G6Event["BLUR"] = "blur"; // touch events

  G6Event["TOUCHSTART"] = "touchstart";
  G6Event["TOUCHMOVE"] = "touchmove";
  G6Event["TOUCHEND"] = "touchend"; // node events

  G6Event["NODE_CONTEXTMENU"] = "node:contextmenu";
  G6Event["NODE_CLICK"] = "node:click";
  G6Event["NODE_DBLCLICK"] = "node:dblclick";
  G6Event["NODE_MOUSEDOWN"] = "node:mousedown";
  G6Event["NODE_MOUSEUP"] = "node:mouseup";
  G6Event["NODE_MOUSEENTER"] = "node:mouseenter";
  G6Event["NODE_MOUSELEAVE"] = "node:mouseleave";
  G6Event["NODE_MOUSEMOVE"] = "node:mousemove";
  G6Event["NODE_MOUSEOUT"] = "node:mouseout";
  G6Event["NODE_MOUSEOVER"] = "node:mouseover";
  G6Event["NODE_DROP"] = "node:drop";
  G6Event["NODE_DRAGOVER"] = "node:dragover";
  G6Event["NODE_DRAGENTER"] = "node:dragenter";
  G6Event["NODE_DRAGLEAVE"] = "node:dragleave";
  G6Event["NODE_DRAGSTART"] = "node:dragstart";
  G6Event["NODE_DRAG"] = "node:drag";
  G6Event["NODE_DRAGEND"] = "node:dragend"; // combo, extends from nodes

  G6Event["COMBO_CONTEXTMENU"] = "combo:contextmenu";
  G6Event["COMBO_CLICK"] = "combo:click";
  G6Event["COMBO_DBLCLICK"] = "combo:dblclick";
  G6Event["COMBO_MOUSEDOWN"] = "combo:mousedown";
  G6Event["COMBO_MOUSEUP"] = "combo:mouseup";
  G6Event["COMBO_MOUSEENTER"] = "combo:mouseenter";
  G6Event["COMBO_MOUSELEAVE"] = "combo:mouseleave";
  G6Event["COMBO_MOUSEMOVE"] = "combo:mousemove";
  G6Event["COMBO_MOUSEOUT"] = "combo:mouseout";
  G6Event["COMBO_MOUSEOVER"] = "combo:mouseover";
  G6Event["COMBO_DROP"] = "combo:drop";
  G6Event["COMBO_DRAGOVER"] = "combo:dragover";
  G6Event["COMBO_DRAGENTER"] = "combo:dragenter";
  G6Event["COMBO_DRAGLEAVE"] = "combo:dragleave";
  G6Event["COMBO_DRAGSTART"] = "combo:dragstart";
  G6Event["COMBO_DRAG"] = "combo:drag";
  G6Event["COMBO_DRAGEND"] = "combo:dragend"; // edge events

  G6Event["EDGE_CONTEXTMENU"] = "edge:contextmenu";
  G6Event["EDGE_CLICK"] = "edge:click";
  G6Event["EDGE_DBLCLICK"] = "edge:dblclick";
  G6Event["EDGE_MOUSEDOWN"] = "edge:mousedown";
  G6Event["EDGE_MOUSEUP"] = "edge:mouseup";
  G6Event["EDGE_MOUSEENTER"] = "edge:mouseenter";
  G6Event["EDGE_MOUSELEAVE"] = "edge:mouseleave";
  G6Event["EDGE_MOUSEMOVE"] = "edge:mousemove";
  G6Event["EDGE_MOUSEOUT"] = "edge:mouseout";
  G6Event["EDGE_MOUSEOVER"] = "edge:mouseover";
  G6Event["EDGE_DROP"] = "edge:drop";
  G6Event["EDGE_DRAGOVER"] = "edge:dragover";
  G6Event["EDGE_DRAGENTER"] = "edge:dragenter";
  G6Event["EDGE_DRAGLEAVE"] = "edge:dragleave"; // canvas events

  G6Event["CANVAS_CONTEXTMENU"] = "canvas:contextmenu";
  G6Event["CANVAS_CLICK"] = "canvas:click";
  G6Event["CANVAS_DBLCLICK"] = "canvas:dblclick";
  G6Event["CANVAS_MOUSEDOWN"] = "canvas:mousedown";
  G6Event["CANVAS_MOUSEUP"] = "canvas:mouseup";
  G6Event["CANVAS_MOUSEENTER"] = "canvas:mouseenter";
  G6Event["CANVAS_MOUSELEAVE"] = "canvas:mouseleave";
  G6Event["CANVAS_MOUSEMOVE"] = "canvas:mousemove";
  G6Event["CANVAS_MOUSEOUT"] = "canvas:mouseout";
  G6Event["CANVAS_MOUSEOVER"] = "canvas:mouseover";
  G6Event["CANVAS_DROP"] = "canvas:drop";
  G6Event["CANVAS_DRAGENTER"] = "canvas:dragenter";
  G6Event["CANVAS_DRAGLEAVE"] = "canvas:dragleave";
  G6Event["CANVAS_DRAGSTART"] = "canvas:dragstart";
  G6Event["CANVAS_DRAG"] = "canvas:drag";
  G6Event["CANVAS_DRAGEND"] = "canvas:dragend"; // timing events

  G6Event["BEFORERENDER"] = "beforerender";
  G6Event["AFTERRENDER"] = "afterrender";
  G6Event["BEFOREADDITEM"] = "beforeadditem";
  G6Event["AFTERADDITEM"] = "afteradditem";
  G6Event["BEFOREREMOVEITEM"] = "beforeremoveitem";
  G6Event["AFTERREMOVEITEM"] = "afterremoveitem";
  G6Event["BEFOREUPDATEITEM"] = "beforeupdateitem";
  G6Event["AFTERUPDATEITEM"] = "afterupdateitem";
  G6Event["BEFOREITEMVISIBILITYCHANGE"] = "beforeitemvisibilitychange";
  G6Event["AFTERITEMVISIBILITYCHANGE"] = "afteritemvisibilitychange";
  G6Event["BEFOREITEMSTATECHANGE"] = "beforeitemstatechange";
  G6Event["AFTERITEMSTATECHANGE"] = "afteritemstatechange";
  G6Event["BEFOREITEMREFRESH"] = "beforeitemrefresh";
  G6Event["AFTERITEMREFRESH"] = "afteritemrefresh";
  G6Event["BEFOREITEMSTATESCLEAR"] = "beforeitemstatesclear";
  G6Event["AFTERITEMSTATESCLEAR"] = "afteritemstatesclear";
  G6Event["BEFOREMODECHANGE"] = "beforemodechange";
  G6Event["AFTERMODECHANGE"] = "aftermodechange";
  G6Event["BEFORELAYOUT"] = "beforelayout";
  G6Event["AFTERLAYOUT"] = "afterlayout";
  G6Event["BEFORECREATEEDGE"] = "beforecreateedge";
  G6Event["AFTERCREATEEDGE"] = "aftercreateedge";
  G6Event["BEFOREGRAPHREFRESHPOSITION"] = "beforegraphrefreshposition";
  G6Event["AFTERGRAPHREFRESHPOSITION"] = "aftergraphrefreshposition";
  G6Event["BEFOREGRAPHREFRESH"] = "beforegraphrefresh";
  G6Event["AFTERGRAPHREFRESH"] = "aftergraphrefresh";
  G6Event["BEFOREANIMATE"] = "beforeanimate";
  G6Event["AFTERANIMATE"] = "afteranimate";
  G6Event["BEFOREPAINT"] = "beforepaint";
  G6Event["AFTERPAINT"] = "afterpaint";
  G6Event["BEFORECOLLAPSEEXPANDCOMBO"] = "beforecollapseexpandcombo";
  G6Event["AFTERCOLLAPSEEXPANDCOMBO"] = "aftercollapseexpandcombo";
  G6Event["GRAPHSTATECHANGE"] = "graphstatechange";
  G6Event["AFTERACTIVATERELATIONS"] = "afteractivaterelations";
  G6Event["NODESELECTCHANGE"] = "nodeselectchange";
  G6Event["TOOLTIPCHANGE"] = "tooltipchange";
  G6Event["WHEELZOOM"] = "wheelzoom";
  G6Event["VIEWPORTCHANGE"] = "viewportchange";
  G6Event["DRAGNODEEND"] = "dragnodeend";
  G6Event["STACKCHANGE"] = "stackchange";
})(G6Event || (G6Event = {}));