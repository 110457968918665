/**
 * 基于 G 的按钮组件
 */
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { deepMix } from '@antv/util';
import { PLAY_PAUSE_BTN } from './constant';

var Button =
/** @class */
function () {
  function Button(cfg) {
    this.config = deepMix({}, cfg);
    this.init();
  }

  Button.prototype.update = function (cfg) {
    this.config = deepMix({}, this.config, cfg);
    this.updateElement();
    this.renderMarker();
  };

  Button.prototype.init = function () {
    this.initElement();
    this.renderMarker();
  };

  Button.prototype.initElement = function () {
    var _a = this.config,
        group = _a.group,
        style = _a.style;
    var _b = style.scale,
        scale = _b === void 0 ? 1 : _b,
        _c = style.offsetX,
        offsetX = _c === void 0 ? 0 : _c,
        _d = style.offsetY,
        offsetY = _d === void 0 ? 0 : _d;
    var x = this.config.x + offsetX;
    var y = this.config.y + offsetY;
    var buttonGroup = group.addGroup({
      name: PLAY_PAUSE_BTN
    });
    this.startMarkerGroup = buttonGroup.addGroup({
      name: PLAY_PAUSE_BTN
    });
    this.circle = group.addShape('circle', {
      attrs: __assign({
        x: x,
        y: y,
        r: this.config.r * scale
      }, style),
      name: PLAY_PAUSE_BTN
    });
    this.startMarker = this.startMarkerGroup.addShape('path', {
      attrs: {
        path: this.getStartMarkerPath(x, y, scale),
        fill: style.stroke || '#aaa'
      }
    });
    this.pauseMarkerGroup = buttonGroup.addGroup({
      name: PLAY_PAUSE_BTN
    });
    var width = 0.25 * this.config.r * scale;
    var height = 0.5 * this.config.r * Math.sqrt(3) * scale;
    this.pauseLeftMarker = this.pauseMarkerGroup.addShape('rect', {
      attrs: {
        x: x - 0.375 * this.config.r * scale,
        y: y - height / 2,
        width: width,
        height: height,
        fill: style.stroke || '#aaa',
        lineWidth: 0
      }
    });
    this.pauseRightMarker = this.pauseMarkerGroup.addShape('rect', {
      attrs: {
        x: x + 1 / 8 * this.config.r * scale,
        y: y - height / 2,
        width: width,
        height: height,
        fill: style.stroke || '#aaa',
        lineWidth: 0
      }
    });
  };

  Button.prototype.updateElement = function () {
    var _a = this.config.style,
        _b = _a.scale,
        scale = _b === void 0 ? 1 : _b,
        _c = _a.offsetX,
        offsetX = _c === void 0 ? 0 : _c,
        _d = _a.offsetY,
        offsetY = _d === void 0 ? 0 : _d;
    var x = this.config.x + offsetX;
    var y = this.config.y + offsetY;
    this.circle.attr('x', x);
    this.circle.attr('y', y);
    this.circle.attr('r', this.config.r * scale);
    this.startMarker.attr('path', this.getStartMarkerPath(x, y, scale));
    var width = 0.25 * this.config.r * scale;
    var height = 0.5 * this.config.r * Math.sqrt(3) * scale;
    this.pauseLeftMarker.attr('x', x - (1 / 4 + 1 / 8) * this.config.r * scale);
    this.pauseLeftMarker.attr('y', y - height / 2);
    this.pauseLeftMarker.attr('width', width);
    this.pauseLeftMarker.attr('height', height);
    this.pauseRightMarker.attr('x', x + 1 / 8 * this.config.r * scale);
    this.pauseRightMarker.attr('y', y - height / 2);
    this.pauseRightMarker.attr('width', width);
    this.pauseRightMarker.attr('height', height);
  };

  Button.prototype.renderMarker = function () {
    if (this.config.isPlay) {
      this.startMarkerGroup.hide();
      this.pauseMarkerGroup.show();
    } else {
      this.startMarkerGroup.show();
      this.pauseMarkerGroup.hide();
    }
  };
  /** 获取播放键 marker path */


  Button.prototype.getStartMarkerPath = function (x, y, scale) {
    var sideLength = 0.5 * this.config.r * Math.sqrt(3) * scale;
    return [['M', x - sideLength / Math.sqrt(3) / 2, y - sideLength / 2], ['L', x + sideLength / Math.sqrt(3), y], ['L', x - sideLength / Math.sqrt(3) / 2, y + sideLength / 2]];
  };

  return Button;
}();

export default Button;