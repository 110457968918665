import { isString } from '@antv/util';
import { createDom, modifyCSS } from '@antv/dom-util';
export var BACKGROUND_STYLE = {
  opacity: 0.5,
  fill: '#000'
};
export var ARROW_STYLE = {
  opacity: 0.5,
  fill: '#000',
  r: 5
};
export var TEXT_STYLE = {
  fill: '#fff',
  fontSize: 12
};
/**
 * 缩略趋势图
 */

var TimeBarTooltip =
/** @class */
function () {
  function TimeBarTooltip(cfg) {
    var _a = cfg.x,
        x = _a === void 0 ? 0 : _a,
        _b = cfg.y,
        y = _b === void 0 ? 0 : _b,
        container = cfg.container,
        text = cfg.text,
        _c = cfg.padding,
        padding = _c === void 0 ? [4, 4, 4, 4] : _c,
        _d = cfg.className,
        className = _d === void 0 ? 'g6-component-timebar-tooltip' : _d,
        _e = cfg.backgroundColor,
        backgroundColor = _e === void 0 ? '#000' : _e,
        _f = cfg.textColor,
        textColor = _f === void 0 ? '#fff' : _f,
        _g = cfg.opacity,
        opacity = _g === void 0 ? 0.8 : _g,
        _h = cfg.fontSize,
        fontSize = _h === void 0 ? 12 : _h;
    this.container = container;
    this.className = className;
    this.backgroundColor = backgroundColor;
    this.textColor = textColor;
    this.x = x;
    this.y = y;
    this.text = text;
    this.padding = padding;
    this.opacity = opacity;
    this.fontSize = fontSize;
    this.render();
  }
  /**
   * 首次渲染
   * @private
   */


  TimeBarTooltip.prototype.render = function () {
    var self = this;
    var className = self.className,
        x = self.x,
        y = self.y,
        backgroundColor = self.backgroundColor,
        textColor = self.textColor,
        text = self.text,
        padding = self.padding,
        opacity = self.opacity,
        fontSize = self.fontSize;
    var parentNode = self.container;
    var container = createDom("<div class='" + className + "' style=\"position: absolute; width: fit-content; height: fit-content; opacity: " + opacity + "\"></div>");

    if (isString(parentNode)) {
      parentNode = document.getElementById(parentNode);
    }

    parentNode.appendChild(container);
    self.parentHeight = parentNode.offsetHeight;
    self.parentWidth = parentNode.offsetWidth;
    modifyCSS(container, {
      visibility: 'hidden',
      top: 0,
      left: 0
    });
    var background = createDom("\n      <div style='position: absolute; white-space:nowrap; background-color: " + backgroundColor + "; font-size: " + fontSize + "px; border-radius: 4px; width: fit-content; height: fit-content; color: " + textColor + "; padding: " + padding[0] + "px " + padding[1] + "px " + padding[2] + "px " + padding[3] + "px'></div>");
    background.innerHTML = text;
    container.appendChild(background);
    self.backgroundDOM = background;
    var arrow = createDom("<div style='position: absolute; width: 0px; height: 0px; border-left: 5px solid transparent; border-right: 5px solid transparent; border-top: 10px solid " + backgroundColor + "'></div>");
    container.appendChild(arrow);
    self.arrowDOM = arrow;
    self.container = container;
  };

  TimeBarTooltip.prototype.show = function (cfg) {
    var self = this;
    var text = cfg.text,
        x = cfg.x,
        y = cfg.y,
        clientX = cfg.clientX,
        clientY = cfg.clientY;
    self.backgroundDOM.innerHTML = text;
    var backgroundWidth = self.backgroundDOM.offsetWidth;
    var backgroundHeight = self.backgroundDOM.offsetHeight;
    var arrowWidth = self.arrowDOM.offsetWidth;
    var arrowHeight = self.arrowDOM.offsetHeight;
    modifyCSS(self.container, {
      top: -backgroundHeight - arrowHeight + "px",
      left: x + "px",
      visibility: 'visible'
    });
    modifyCSS(self.backgroundDOM, {
      marginLeft: -backgroundWidth / 2 + "px"
    });
    modifyCSS(self.arrowDOM, {
      marginLeft: -arrowWidth / 2 + "px",
      top: backgroundHeight + "px"
    });
    var left = x - backgroundWidth / 2;
    var right = x + backgroundWidth / 2;

    if (left < 0) {
      modifyCSS(self.backgroundDOM, {
        marginLeft: -backgroundWidth / 2 - left + "px"
      });
    } else if (right > self.parentWidth) {
      modifyCSS(self.backgroundDOM, {
        marginLeft: -backgroundWidth / 2 - right + self.parentWidth + 12 + "px"
      });
    }
  };

  TimeBarTooltip.prototype.hide = function () {
    modifyCSS(this.container, {
      top: 0,
      left: 0,
      visibility: 'hidden'
    });
  };

  return TimeBarTooltip;
}();

export default TimeBarTooltip;