var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");

    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
/**
 * 基于 G 的时间轴组件
 */


import { Canvas as GCanvas } from '@antv/g-canvas';
import { Canvas as GSVGCanvas } from '@antv/g-svg';
import { createDom, modifyCSS } from '@antv/dom-util';
import Base from '../base';
import TrendTimeBar from './trendTimeBar';
import TimeBarSlice from './timeBarSlice';
import { VALUE_CHANGE } from './constant';
import { isString, throttle } from '@antv/util'; // simple 版本默认高度

var DEFAULT_SIMPLE_HEIGHT = 4; // trend 版本默认高度

var DEFAULT_TREND_HEIGHT = 26;

var TimeBar =
/** @class */
function (_super) {
  __extends(TimeBar, _super);

  function TimeBar() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  TimeBar.prototype.getDefaultCfgs = function () {
    return {
      container: null,
      className: 'g6-component-timebar',
      padding: 10,
      type: 'trend',
      trend: {
        data: [],
        isArea: false,
        smooth: true
      },
      controllerCfg: {
        speed: 1,
        loop: false
      },
      slider: {
        start: 0.1,
        end: 0.9,
        minText: 'min',
        maxText: 'max'
      },
      tick: {
        start: 0.1,
        end: 0.9,
        data: []
      },
      textStyle: {},
      filterEdge: false
    };
  };
  /**
   * 初始化 TimeBar 的容器
   */


  TimeBar.prototype.initContainer = function () {
    var graph = this.get('graph');
    var _a = this._cfgs,
        width = _a.width,
        height = _a.height;
    var className = this.get('className') || 'g6-component-timebar';
    var container = this.get('container');
    var graphContainer = this.get('graph').get('container');
    var timeBarContainer;

    if (!container) {
      timeBarContainer = createDom("<div class='" + className + "'></div>");
      modifyCSS(timeBarContainer, {
        position: 'relative'
      });
    } else {
      if (isString(container)) {
        container = document.getElementById(container);
      }

      timeBarContainer = container;
    }

    graphContainer.appendChild(timeBarContainer);
    this.set('timeBarContainer', timeBarContainer);
    var canvas;
    var renderer = graph.get('renderer');

    if (renderer === 'SVG') {
      canvas = new GSVGCanvas({
        container: timeBarContainer,
        width: width,
        height: height
      });
    } else {
      canvas = new GCanvas({
        container: timeBarContainer,
        width: width,
        height: height
      });
    }

    this.set('canvas', canvas);
  };

  TimeBar.prototype.init = function () {
    this.initContainer();
    var canvas = this.get('canvas');
    var timeBarGroup = canvas.addGroup({
      name: 'timebar-group'
    });
    this.set('timeBarGroup', timeBarGroup);
    this.renderTrend();
    this.initEvent();
    var fontFamily = typeof window !== 'undefined' ? window.getComputedStyle(document.body, null).getPropertyValue('font-family') || 'Arial, sans-serif' : 'Arial, sans-serif';
    this.set('fontFamily', fontFamily);
  };

  TimeBar.prototype.renderTrend = function () {
    var _a = this._cfgs,
        width = _a.width,
        x = _a.x,
        y = _a.y,
        padding = _a.padding,
        type = _a.type,
        trend = _a.trend,
        slider = _a.slider,
        controllerCfg = _a.controllerCfg,
        textStyle = _a.textStyle,
        tick = _a.tick,
        backgroundStyle = _a.backgroundStyle,
        foregroundStyle = _a.foregroundStyle;

    var data = trend.data,
        other = __rest(trend, ["data"]);

    var realWidth = width - 2 * padding;
    var defaultHeight = type === 'trend' ? DEFAULT_TREND_HEIGHT : DEFAULT_SIMPLE_HEIGHT;
    var graph = this.get('graph');
    var group = this.get('timeBarGroup');
    var canvas = this.get('canvas');
    var timebar = null;

    if (type === 'trend' || type === 'simple') {
      timebar = new TrendTimeBar(__assign(__assign({
        graph: graph,
        canvas: canvas,
        group: group,
        type: type,
        x: x + padding,
        y: type === 'trend' ? y + padding : y + padding + 15,
        width: realWidth,
        height: defaultHeight,
        padding: padding,
        backgroundStyle: backgroundStyle,
        foregroundStyle: foregroundStyle,
        trendCfg: __assign(__assign({}, other), {
          data: data.map(function (d) {
            return d.value;
          })
        })
      }, slider), {
        tick: {
          ticks: data,
          tickLabelFormatter: tick.tickLabelFormatter,
          tickLabelStyle: tick.tickLabelStyle,
          tickLineStyle: tick.tickLineStyle
        },
        handlerStyle: __assign(__assign({}, slider.handlerStyle), {
          height: slider.height || defaultHeight
        }),
        controllerCfg: controllerCfg,
        textStyle: textStyle
      }));
    } else if (type === 'tick') {
      // 刻度时间轴
      timebar = new TimeBarSlice(__assign({
        graph: graph,
        canvas: canvas,
        group: group,
        x: x + padding,
        y: y + padding
      }, tick));
    }

    this.set('timebar', timebar);
  };

  TimeBar.prototype.filterData = function (evt) {
    var _a;

    var value = evt.value;
    var trendData = null;
    var type = this._cfgs.type;

    if (type === 'trend' || type === 'simple') {
      trendData = this._cfgs.trend.data;
    } else if (type === 'tick') {
      trendData = this._cfgs.tick.data;
    }

    if (!trendData || trendData.length === 0) {
      console.warn('请配置 TimeBar 组件的数据');
      return;
    }

    var rangeChange = this.get('rangeChange');
    var graph = this.get('graph');
    var min = Math.round(trendData.length * value[0]);
    var max = Math.round(trendData.length * value[1]);
    max = max >= trendData.length ? trendData.length - 1 : max;
    min = min >= trendData.length ? trendData.length - 1 : min;
    var tickLabelFormatter = (_a = this._cfgs.tick) === null || _a === void 0 ? void 0 : _a.tickLabelFormatter;
    var minText = tickLabelFormatter ? tickLabelFormatter(trendData[min]) : trendData[min].date;
    var maxText = tickLabelFormatter ? tickLabelFormatter(trendData[max]) : trendData[max].date;

    if (type !== 'tick') {
      var timebar = this.get('timebar');
      timebar.setText(minText, maxText);
    }

    if (rangeChange) {
      rangeChange(graph, minText, maxText);
    } else {
      // 自动过滤数据，并渲染 graph
      if (!this.cacheGraphData || this.cacheGraphData.nodes && this.cacheGraphData.nodes.length === 0) {
        this.cacheGraphData = graph.get('data'); // graph.save() as GraphData;
      } // 过滤不在 min 和 max 范围内的节点


      var filterData = this.cacheGraphData.nodes.filter(function (d) {
        return d.date >= trendData[min].date && d.date <= trendData[max].date;
      });
      var nodeIds_1 = filterData.map(function (node) {
        return node.id;
      });
      var fileterEdges = [];

      if (this.cacheGraphData.edges) {
        // 过滤 source 或 target 不在 min 和 max 范围内的边
        fileterEdges = this.cacheGraphData.edges.filter(function (edge) {
          return nodeIds_1.includes(edge.source) && nodeIds_1.includes(edge.target);
        });

        if (this.get('filterEdge')) {
          fileterEdges = fileterEdges.filter(function (edge) {
            return edge.date >= trendData[min].date && edge.date <= trendData[max].date;
          });
        }
      }

      graph.changeData({
        nodes: filterData,
        edges: fileterEdges
      });
    }
  };

  TimeBar.prototype.initEvent = function () {
    var _this = this;

    var start = 0;
    var end = 0;
    var type = this._cfgs.type;

    if (!type || type === 'trend' || type === 'simple') {
      start = this._cfgs.slider.start;
      end = this._cfgs.slider.end;
    } else if (type === 'tick') {
      start = this._cfgs.tick.start;
      end = this._cfgs.tick.end;
    }

    var graph = this.get('graph');
    graph.on('afterrender', function (e) {
      _this.filterData({
        value: [start, end]
      });
    }); // 时间轴的值发生改变的事件

    graph.on(VALUE_CHANGE, throttle(function (e) {
      _this.filterData(e);
    }, 200, {
      trailing: true,
      leading: true
    }));
  };

  TimeBar.prototype.destroy = function () {
    var timebar = this.get('timebar');

    if (timebar && timebar.destory) {
      timebar.destory();
    }

    _super.prototype.destroy.call(this);

    var timeBarContainer = this.get('timeBarContainer');

    if (timeBarContainer) {
      var container = this.get('container');

      if (!container) {
        container = this.get('graph').get('container');
      }

      if (isString(container)) {
        container = document.getElementById(container);
      }

      container.removeChild(timeBarContainer);
    }
  };

  return TimeBar;
}(Base);

export default TimeBar;